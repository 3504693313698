

























import { Component, Vue, Prop } from "vue-property-decorator";
import {
  PostUsDetail,
  PostInbodyDetail,
  PostLepuDetail,
  PostK5Detail,
  getShebeiDetail,
} from "@/request/workstation";
import { getShebeiList } from "@/request/custom";
import Read from "./components/read.vue";
import Detail from "./components/detail.vue";
import LeftList from "../../components/left-list/left-list.vue";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Read,
    Detail,
    LeftList,
    Empty,
  },
})
export default class Name extends Vue {
  private leftNavList: any = [];
  private detail: any = {};
  private get patient() {
    return this.$store.state.patient;
  }
  private get user() {
    return this.$store.state.user || {};
  }
  private get configuration() {
    return this.$store.state.configuration;
  }
  private get patientFileFilter() {
    return this.$store.state.patientFileFilter;
  }

  private getLeftData() {
    return new Promise((resolve, reject) => {
      const params: any = {
        patient_id: this.patient.patient_id,
      };
      getShebeiList(this, params).then((d1: any) => {
        this.leftNavList = d1;
        resolve(d1);
      });
    });
  }
  private updateRightData() {
    let loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    // body_scale(体脂秤报告)
    // inbody(InBody报告)
    // k5(一体机报告)
    // lepu(乐普健康检测报告)
    // chaosheng()
    if (this.patientFileFilter.thirdTab.type == "chaosheng") {
      const params: any = {
        patient_id: this.patient.patient_id,
        report_id: this.patientFileFilter.thirdTab.report_id,
      };
      PostUsDetail(this, params, loading)
        .then((data: any) => {
          loading.close();
          this.detail = data;
        })
        .catch(() => {
          loading.close();
        });
    } else if (this.patientFileFilter.thirdTab.type == "lepu") {
      const params: any = {
        patient_id: this.patient.patient_id,
        report_id: this.patientFileFilter.thirdTab.report_id,
      };
      PostLepuDetail(this, params, loading)
        .then((data: any) => {
          loading.close();
          this.detail = data;
        })
        .catch(() => {
          loading.close();
        });
    } else if (this.patientFileFilter.thirdTab.type == "k5") {
      const params: any = {
        patient_id: this.patient.patient_id,
        k5_data_id: this.patientFileFilter.thirdTab.report_id,
      };
      PostK5Detail(this, params, loading)
        .then((data: any) => {
          loading.close();
          this.detail = data;
        })
        .catch(() => {
          loading.close();
        });
    } else if (this.patientFileFilter.thirdTab.type == "inbody") {
      const params: any = {
        patient_id: this.patient.patient_id,
        inbody_data_id: this.patientFileFilter.thirdTab.report_id,
      };
      PostInbodyDetail(this, params, loading)
        .then((data: any) => {
          loading.close();
          this.detail = data;
        })
        .catch(() => {
          loading.close();
        });
    } else {
      const params: any = {
        params: {
          id: this.patientFileFilter.thirdTab.report_id,
          report_type: this.patientFileFilter.thirdTab["报告名称"],
        },
      };
      getShebeiDetail(this, params, loading)
        .then((data: any) => {
          loading.close();
          this.detail = data;
        })
        .catch(() => {
          loading.close();
        });
    }
  }
  private mounted() {
    this.$store.commit("updateIfCompose", false);
    this.getLeftData().then(() => {
      // 如果没有选中项，默认选中第一个
      if (!this.patientFileFilter.thirdTab) {
        this.$store.commit(
          "updatePatientFileFilterRefrshTime",
          new Date().getTime()
        );
      } else {
        this.updateRightData();
      }
    });
  }
}
