



















































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { GetZidian } from "@/request/custom";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private detail: any;
  private yundongTypes: any = [];
  private cTab: any = 1;
  private changeYundongTab(index: any) {
    this.cTab = index + 1;
    this.$forceUpdate();
  }
  private getType() {
    const params: any = {
      data_type: "运动库类别",
    };
    GetZidian(this, params).then((res: any) => {
      this.yundongTypes = [];
      res["运动库类别"].forEach((ele: any) => {
        this.yundongTypes.push(ele.value);
      });
    });
  }
  private mounted() {
    this.getType();
  }
}
