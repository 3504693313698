

































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private data: any;
  private goReport(item: any) {
    const url: any = window.location.href.split("/#/")[0];
    window.open(url + "/#/main/report/ceping-report?id=" + item.content_id);
  }
}
