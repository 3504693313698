

































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
@Component({})
export default class Name extends mixins(listMixin) {
  private data: any = [];
  private tableRowClassName(d: any) {
    if (!d.row.children) {
      return "childrenClass";
    } else {
      return "";
    }
  }
  private openAdd() {
    this.$router.push({
      path: "/main/workstation/patient-yizhu-edit",
      query: {
        id: "1212",
      },
    });
  }
  private load(tree: any, treeNode: any, resolve: any) {
    setTimeout(() => {
      resolve([
        {
          id: 31,
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          id: 32,
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
      ]);
    }, 1000);
  }
  getList() {
    this.data = [
      {
        id: 1,
        date: "2016-05-02",
        name: "王小虎",
        address: "上海市普陀区金沙江路 1518 弄",
        children: [
          {
            id: 11,
            date: "2016-05-01",
            name: "王小虎",
            address: "上海市普陀区金沙江路 1519 弄",
          },
          {
            id: 12,
            date: "2016-05-01",
            name: "王小虎",
            address: "上海市普陀区金沙江路 1519 弄",
          },
        ],
      },
      {
        id: 2,
        date: "2016-05-04",
        name: "王小虎",
        address: "上海市普陀区金沙江路 1517 弄",
        children: [
          {
            id: 21,
            date: "2016-05-01",
            name: "王小虎",
            address: "上海市普陀区金沙江路 1519 弄",
          },
          {
            id: 22,
            date: "2016-05-01",
            name: "王小虎",
            address: "上海市普陀区金沙江路 1519 弄",
          },
        ],
      },
      {
        id: 3,
        date: "2016-05-01",
        name: "王小虎",
        address: "上海市普陀区金沙江路 1519 弄",
        children: [
          {
            id: 31,
            date: "2016-05-01",
            name: "王小虎",
            address: "上海市普陀区金沙江路 1519 弄",
          },
          {
            id: 32,
            date: "2016-05-01",
            name: "王小虎",
            address: "上海市普陀区金沙江路 1519 弄",
          },
        ],
      },
      {
        id: 4,
        date: "2016-05-03",
        name: "王小虎",
        address: "上海市普陀区金沙江路 1516 弄",
        children: [
          {
            id: 41,
            date: "2016-05-01",
            name: "王小虎",
            address: "上海市普陀区金沙江路 1519 弄",
          },
          {
            id: 42,
            date: "2016-05-01",
            name: "王小虎",
            address: "上海市普陀区金沙江路 1519 弄",
          },
        ],
      },
    ];
    this.filters.total_count = 36;
    this.filters.total_page = 4;
    if (this.data.length > 0) {
      this.ifShowEmpty = false;
    } else {
      this.ifShowEmpty = true;
    }
  }
  mounted() {
    this.getList();
  }
}
