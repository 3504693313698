import { render, staticRenderFns } from "./title-sub.vue?vue&type=template&id=0ce57655&scoped=true&"
import script from "./title-sub.vue?vue&type=script&lang=ts&"
export * from "./title-sub.vue?vue&type=script&lang=ts&"
import style0 from "./title-sub.vue?vue&type=style&index=0&id=0ce57655&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ce57655",
  null
  
)

export default component.exports