


















































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  patientList,
  getDiseases,
  patientDetail,
  getOptions,
  searchBiaoqian,
} from "@/request/doctor";
import { PostLaiyuans } from "@/request/yichang";
@Component({})
export default class Name extends Vue {
  private filter: any = {
    标签: [],
    服务套餐: [],
    来源: "",
    慢病: "",
    签约状态: "",
    search: "",
    startTime: "",
    endTime: "",
    current_page: 1,
    page_count: 20,
    totalCount: 0,
    total_page: 0,
  };
  private diseases: any = [];
  private H: any = "";
  private cpatient = 0;
  private disabledStartDate: any = this.beginDate();
  private disabledEndDate: any = this.endDate();
  private list: any = [];
  private biaoqians: any = [];
  private taocans: any = [
    "心脏康复运动",
    "营养减重",
    "功能医学抗衰老",
    "精准就医",
    "中医调理",
    "健康管理",
  ];
  private sources: any = [];
  private jibings: any = [];
  private shenfengs: any = [];
  private content: any = {};
  private doctors: any = [];
  private beginDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.filter.endTime) {
          return (
            time.getTime() > new Date(self.filter.endTime).getTime() ||
            time.getTime() > Date.now()
          );
        } else {
          return time.getTime() > Date.now();
        }
      },
    };
  }
  private endDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.filter.startTime) {
          return (
            new Date(self.filter.startTime).getTime() > time.getTime() ||
            time.getTime() > Date.now()
          );
        } else {
          return time.getTime() > Date.now();
        }
      },
    };
  }
  private get user() {
    return this.$store.state.user;
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private get resizeTime() {
    return this.$store.state.resizeTime;
  }
  @Watch("resizeTime", { immediate: true })
  private resizeTimeChange() {
    if (this.resizeTime) {
      setTimeout(() => {
        this.countH();
      }, 100);
    }
  }
  private countH() {
    this.H = document.body.clientHeight - 50 - 10 - 10;
  }
  private focus(e: any) {
    if (e) {
      this.remoteBiaoqian("");
    }
  }
  private doctorFocus(e: any) {
    if (e) {
      this.remoteDoctor("");
    }
  }
  private search() {
    this.filter.current_page = 1;
    this.getList();
  }
  private changeTab(part: any) {
    this.filter["签约状态"] = part;
    this.getList();
  }
  private getLaiyuan() {
    PostLaiyuans(this).then((res: any) => {
      this.sources = res;
    });
  }
  private getJibing() {
    const params: any = {
      data_type: "疾病类型",
    };
    getOptions(this, params).then((res: any) => {
      this.jibings = res["疾病类型"];
    });
  }
  private getShenfengs() {
    const params: any = {
      data_type: "客户身份",
    };
    getOptions(this, params).then((res: any) => {
      this.shenfengs = res["客户身份"];
    });
  }
  private remoteBiaoqian(e: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    searchBiaoqian(this, params).then((res: any) => {
      this.biaoqians = res;
    });
  }
  private remoteDoctor(e: any) {
    const params: any = {
      params: {
        search: e,
        data_type: "创建医生",
      },
    };
    searchBiaoqian(this, params).then((res: any) => {
      this.doctors = res;
    });
  }
  private selectPatient(item: any) {
    const params = {
      patient_id: item.patient_id,
      source: "pc医生",
    };
    patientDetail(this, params).then((res: any) => {
      this.$store.commit("updatePatient", res);
      localStorage.setItem("patientFileFilter", "");
      this.$emit("changePatient");
    });
  }
  private jieyueRefresh() {
    this.list.forEach((ele: any, index: any) => {
      if (this.patient.patient_id == ele.patient_id) {
        this.list[index] = JSON.parse(JSON.stringify(this.patient));
      }
    });
    this.$forceUpdate();
  }
  private getList(part: any = "") {
    console.log(this.filter);
    // part 存在表示是触底加载下一页
    if (part) {
      if (this.filter.current_page === this.filter.total_page) {
        return;
      }
      this.filter.current_page++;
      const params = {
        current_page: this.filter.current_page,
        page_count: this.filter.page_count,
        sort_value: 1,
        search: this.filter.search,
        来源: this.filter["渠道来源"],
        服务套餐: this.filter["服务套餐"],
        疾病类型: this.filter["疾病类型"],
        客户标签: this.filter.doctor,
      };
      patientList(this, params).then((res: any) => {
        this.list = this.list.concat(res.patients);
        this.content = res;
        this.filter.totalCount = res.total_count;
        this.filter.total_page = res.total_page;
      });
    } else {
      const params = {
        current_page: this.filter.current_page,
        page_count: this.filter.page_count,
        sort_value: 1,
        search: this.filter.search,
        来源: this.filter["渠道来源"],
        服务套餐: this.filter["服务套餐"],
        疾病类型: this.filter["疾病类型"],
        // 客户标签: this.filter["标签"].concat(this.filter.doctor),
        客户标签: this.filter.doctor,
      };
      patientList(this, params).then((res: any) => {
        this.list = res.patients;
        this.content = res;
        this.filter.totalCount = res.total_count;
        this.filter.total_page = res.total_page;
      });
    }
  }
  private getDiseases() {
    getDiseases(this).then((res: any) => {
      this.diseases = res;
    });
  }
  private addPatient() {
    this.$router.push({
      path: "/main/workstation/patient-edit-message",
    });
  }
  mounted() {
    // this.H = document.body.clientHeight - 50 - 14 - 14;
    this.countH();
    this.getList();
    this.getLaiyuan();
    this.getJibing();
    this.getShenfengs();
    const el: any = document.querySelector(".left-patient");
    el.onscroll = () => {
      const scrollTop = el.scrollTop;
      const scrollHeight = el.scrollHeight;
      if (this.H + scrollTop - scrollHeight >= -20) {
        this.getList("add");
      }
    };
    this.getDiseases();
  }
}
