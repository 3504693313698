




































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import Read from "./read.vue";
import { PostDeviceList } from "@/request/workstation";
import Yichangchuli from "../../../../../yichang/components/yichangchuli.vue";
import Export from "../export/export.vue";
import Edit from "../edit.vue";
@Component({
  components: {
    Read,
    Export,
    Yichangchuli,
    Edit,
  },
})
export default class Name extends mixins(listMixin) {
  @Prop()
  private part: any;
  @Watch("part", { immediate: true })
  private partChange() {
    if (this.part === "血压") {
      this.getList();
    }
  }
  @Prop()
  private updateListTime: any;
  @Watch("updateListTime", { immediate: true })
  private updateListTimeChange() {
    if (!this.updateListTime) {
      return;
    }
    if (this.part === "血压") {
      this.getList();
    }
  }
  private ifShowRead: any = false;
  private data: any = [];
  private readId: any = "";
  private readTime: any = "";
  private ifShowTishi: any = false;
  private ifChuli: any = false;
  private cData: any = {};
  private showEdit: any = false;
  private editData: any = {};
  private get patient() {
    return this.$store.state.patient;
  }
  private get user() {
    return this.$store.state.user;
  }
  private goEdit(item: any) {
    this.editData = item;
    this.showEdit = true;
  }
  private cancel() {
    this.showEdit = false;
  }
  private update() {
    this.getList();
    this.readTime = new Date().getTime();
    this.cancel();
  }
  private openChuli(item: any) {
    this.cData = JSON.parse(JSON.stringify(item));
    this.cData.check_time = this.cData.date;
    this.ifChuli = true;
  }
  private closeChuli() {
    this.ifChuli = false;
    this.getList();
  }

  private goRead(item: any) {
    this.readId = item.date;
    this.readTime = new Date().getTime(); // 用来控制每次查看时都重新获取数据
    this.ifShowRead = true;
  }
  private openTishi() {
    this.ifShowTishi = true;
  }
  getList() {
    this.data = [];
    const params: any = {
      patient_id: this.patient.patient_id,
      type: "血压",
      current_page: this.filters.current_page,
      page_count: this.filters.page_count,
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostDeviceList(this, params)
      .then((data: any) => {
        loading.close();
        this.data = data.datas;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (data.datas.length == 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
}
