






























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import TimeRange from "@/components/time-range/time-range.vue";
import { ExportZhibiao } from "@/request/workstation";
@Component({
  components: {
    TimeRange,
  },
})
export default class Name extends Vue {
  @Prop()
  private type: any;
  @Prop()
  private ifShowExport: any;
  @Watch("ifShowExport", { immediate: true })
  private ifShowExportChange(val: any) {
    if (val) {
      this.data = {};
    }
  }
  private ifShow: any = true;
  private data: any = {};

  private get patient() {
    return this.$store.state.patient;
  }

  private timeChange(e: any) {
    this.data = e;
  }
  private cancel() {
    this.$emit("cancel");
  }
  private save() {
    if (!this.data.startTime) {
      this.$message.warning("请选择开始时间");
      return;
    }
    if (!this.data.endTime) {
      this.$message.warning("请选择结束时间");
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      patient_id: this.patient.patient_id,
      type: this.type,
      起始时间: this.data.startTime,
      结束时间: this.data.endTime,
    };
    ExportZhibiao(this, params, loading).then((res: any) => {
      loading.close();
      this.$message.success("导出成功！");
      this.cancel();
      if (res.count < 1) {
        this.$message.warning("当前所选时间段无数据!");
      } else {
        window.open(res.path);
      }
    });
  }
}
