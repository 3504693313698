

































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { PostZhibiao, getYundongType } from "@/request/workstation";
@Component({})
export default class Name extends Vue {
  @Prop()
  private type: any;
  @Watch("type", { immediate: true })
  private typeChange() {
    if (this.type == "血糖") {
      this.options = [
        "空腹",
        "早餐后",
        "午餐前",
        "午餐后",
        "晚餐前",
        "晚餐后",
        "睡前",
        "凌晨",
        "随机",
      ];
      this.cOpt = "";
    } else if (this.type == "血压") {
      this.options = [
        "静息",
        "吸烟",
        "进食",
        "饮酒",
        "喝咖啡",
        "剧烈运动",
        "起床后",
        "睡前",
      ];
      this.cOpt = "";
    } else if (this.type == "心电") {
      this.options = ["单导", "6导", "12导"];
      this.cOpt = "";
    } else if (this.type == "脉搏") {
      this.options = [
        "静息",
        "吸烟",
        "饮酒",
        "喝咖啡",
        "起床后",
        "睡前",
        "剧烈运动",
        "进食",
      ];
      this.cOpt = "";
    } else if (this.type == "心率") {
      this.options = ["静息", "运动"];
      this.cOpt = "";
    }
  }
  @Prop()
  private data: any;
  @Prop()
  private ifShow: any;
  @Watch("ifShow", { immediate: true })
  private ifShowChange() {
    this.ifShowAdd = this.ifShow;
    if (this.ifShow) {
      this.yundongOptions = [];
      getYundongType(this).then((res) => {
        const arr: any = [
          "健身类",
          "休闲运动",
          "球类",
          "水上项目",
          "冰雪项目",
          "极限运动",
        ];
        arr.forEach((ele: any) => {
          const obj = {
            name: ele,
            children: res[ele],
          };
          this.yundongOptions.push(obj);
        });
      });
      this.data["测量时间"] = this.data.begin_time;
      if (this.type == "血糖") {
        this.data["类别"] = this.data["血糖时间"];
      } else if (this.type == "血压") {
        this.data["类别"] = this.data["测量前活动"];
      } else if (this.type == "心率") {
        this.data["类别"] = this.data["心率时间"];
      }
      this.addData = JSON.parse(JSON.stringify(this.data));
    }
  }
  private ifShowAdd: any = false;
  private addData: any = {};
  private yundongOptions: any = [];
  private cOpt: any = "";
  private options: any = [];
  private disabledStartDate: any = this.beginDate();
  private beginDate() {
    return {
      disabledDate(time: any) {
        return time.getTime() > Date.now();
      },
    };
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private get user() {
    return this.$store.state.user || {};
  }
  private cancel() {
    this.$emit("cancel");
  }
  private changeOpt(item: any) {
    this.addData["类别"] = item;
    this.$forceUpdate();
  }
  private yundongTypeChange(e: any) {
    if (!e || e.length == 0) {
      this.addData.unit = "";
      return;
    }
    let d: any = {};
    this.yundongOptions.forEach((element: any) => {
      if (element.name == e[0]) {
        element.children.forEach((ele: any) => {
          if (ele.name == e[1]) {
            d = ele;
          }
        });
      }
    });
    this.addData["距离"] = null;
    this.addData["距离单位"] = d.unit;
  }
  private addSure() {
    if (this.type == "血糖" && !this.addData["value"]) {
      this.$message.warning("请填写血糖！");
      return;
    }
    if (this.type == "心率" && !this.addData["value"]) {
      this.$message.warning("请填写心率！");
      return;
    }
    if (this.type == "心电" && !this.addData["心电图"]) {
      this.$message.warning("请上传心电图！");
      return;
    }
    if (this.type == "血氧饱和度" && !this.addData["value"]) {
      this.$message.warning("请填写血氧饱和度！");
      return;
    }
    if (this.type == "脉搏" && !this.addData["脉搏"]) {
      this.$message.warning("请填写脉搏！");
      return;
    }
    if (this.type == "体温" && !this.addData["value"]) {
      this.$message.warning("请填写体温！");
      return;
    }
    if (this.type == "体重" && !this.addData["value"]) {
      this.$message.warning("请填写体重！");
      return;
    }
    if (this.type == "血压" && !this.addData["收缩压"]) {
      this.$message.warning("请填写收缩压！");
      return;
    }
    if (this.type == "血压" && !this.addData["舒张压"]) {
      this.$message.warning("请填写舒张压！");
      return;
    }
    if (
      (this.type == "血糖" || this.type == "心率") &&
      !this.addData["测量时间"]
    ) {
      this.$message.warning("请选择测量时间！");
      return;
    }
    if (this.type == "血糖" && !this.addData["类别"]) {
      this.$message.warning("请选择测量时间段！");
      return;
    }
    if (this.type == "心电" && !this.addData["类别"]) {
      this.$message.warning("请选择心电图类别！");
      return;
    }
    if (this.type == "脉搏" && !this.addData["类别"]) {
      this.$message.warning("请选择测量前活动！");
      return;
    }
    if (this.type == "血压" && !this.addData["类别"]) {
      this.$message.warning("请选择测量前活动！");
      return;
    }
    if (this.type == "心率" && !this.addData["类别"]) {
      this.$message.warning("请选择测量前活动！");
      return;
    }
    if (
      this.type == "运动" &&
      (!this.addData["运动类型"] || !this.addData["运动类型"][1])
    ) {
      this.$message.warning("请选择运动类型！");
      return;
    }
    if (this.type == "运动" && !this.addData["测量时间"]) {
      this.$message.warning("请选择开始时间！");
      return;
    }
    if (this.type == "运动" && !this.addData["运动时长"]) {
      this.$message.warning("请选择运动时长！");
      return;
    }
    const params: any = {
      patient_id: this.patient.patient_id,
      来源: "pc医生",
      type: this.type,
      测量时间: this.addData["测量时间"],
    };
    if (this.type == "血糖") {
      params["血糖"] = this.addData["value"];
      params["血糖时间"] = this.addData["类别"];
    }
    if (this.type == "心率") {
      params["心率"] = this.addData["value"];
      params["心率时间"] = this.addData["类别"];
    }
    if (this.type == "心电") {
      const obj: any = {
        value: [],
      };
      obj.value = [{ url: this.addData["心电图"] }];
      params["心电"] = obj;
      params["心电类别"] = this.addData["类别"];
    }
    if (this.type == "血氧饱和度") {
      params["血氧饱和度"] = this.addData["value"];
    }
    if (this.type == "脉搏") {
      params["脉搏"] = this.addData["脉搏"];
      params["脉搏时间"] = this.addData["类别"];
    }
    if (this.type == "体温") {
      params["体温"] = this.addData["value"];
    }
    if (this.type == "血压") {
      params["舒张压"] = this.addData["舒张压"];
      params["收缩压"] = this.addData["收缩压"];
      params["血压活动"] = this.addData["类别"];
    }
    if (this.type == "体重") {
      params["体重"] = this.addData["value"];
    }
    if (this.type == "运动") {
      params["运动类型"] = this.addData["运动类型"];
      params["运动时长"] = this.addData["运动时长"];
      params["平均心率"] = this.addData["平均心率"];
      params["最大心率"] = this.addData["最大心率"];
      params["距离"] = this.addData["距离"];
      params["距离单位"] = this.addData["距离单位"];
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    params.warning_id = this.addData.warning_id;
    PostZhibiao(this, params, loading).then((res: any) => {
      loading.close();
      this.ifShowAdd = false;
      this.$message.warning("编辑成功");
      this.$emit("update");
      //   // 刷新时间和图数据
      //   if (this.type !== "心电") {
      //     this.reset();
      //   }
      //   // 刷新列表
      //   this.$emit("updateList");
    });
  }
}
