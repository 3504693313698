































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  getCepingDetail,
  deleteJiangkangceping,
  GetCepingTypeList,
} from "@/request/doctor";
import { GetCepingliangbiao } from "@/request/workstation";
import { getReportList, delReport, getReportDetail } from "@/request/custom";
import Read from "./read.vue";
import Empty from "@/components/empty.vue";
import LeftList from "../../components/left-list/left-list.vue";
@Component({
  components: {
    Read,
    Empty,
    LeftList,
  },
})
export default class Name extends Vue {
  private leftNavList: any = [];
  private detail: any = {};
  private IfShowAdd: any = false;
  private addValue: any = "";
  private editData: any = {};
  private options: any = [];
  private types: any = [];
  private disabledEndDate: any = this.endDate();
  private endDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        return time.getTime() > Date.now();
      },
    };
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private get user() {
    return this.$store.state.user || {};
  }
  private get configuration() {
    return this.$store.state.configuration;
  }
  private get patientFileFilter() {
    return this.$store.state.patientFileFilter;
  }
  private editTimeSure() {
    // 编辑后需要重新更新选中的数据
    this.getLeftData().then((res: any) => {
      res.forEach((ele: any) => {
        if (ele.content_id === this.patientFileFilter.thirdTab.content_id) {
          const d: any = JSON.parse(JSON.stringify(ele));
          this.$store.commit("updatePatientFileFilter", d);
          this.updateRightData();
        }
      });
    });
  }
  private delOne(e: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      data: {
        assess_report_id: e.assess_report_id,
      },
    };
    delReport(this, params, loading)
      .then(() => {
        loading.close();
        this.$message.success("删除成功！");
        this.getLeftData().then((res: any) => {
          let obj: any = JSON.parse(JSON.stringify(this.patientFileFilter));
          if (res.length > 0) {
            this.$store.commit(
              "updatePatientFileFilterRefrshTime",
              new Date().getTime()
            );
          } else {
            obj.thirdTab = "";
            this.$store.commit("updatePatientFileFilter", obj);
            this.detail = {};
          }
        });
      })
      .catch(() => {
        loading.close();
      });
  }
  private remoteMethod(e: any) {
    GetCepingliangbiao(this).then((res: any) => {
      this.options = res;
    });
  }
  private openAdd() {
    this.IfShowAdd = true;
    this.addValue = "心血管病风险评估";
  }
  private addSure() {
    if (!this.addValue) {
      this.$message.warning("请输入报告名称！");
      return;
    }
    this.$store.commit("updateIfCompose", true);
    this.IfShowAdd = false;
    this.$router.push({
      path: "/main/workstation/patient-pinggu-introduce",
      query: {
        type: this.addValue,
      },
    });
  }
  private goEdit() {
    this.$router.push({
      path: "/main/workstation/patient-pinggu-report",
      query: {
        id: this.detail.assess_report_id,
      },
    });
    this.$store.commit("updateIfCompose", true);
  }
  private getLeftData() {
    return new Promise((resolve, reject) => {
      const params: any = {
        params: {
          patient_id: this.patient.patient_id,
        },
      };
      getReportList(this, params).then((res) => {
        this.leftNavList = res;
        resolve(res);
      });
    });
  }
  private updateRightData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      assess_report_id: this.patientFileFilter.thirdTab.assess_report_id,
    };
    getReportDetail(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.detail = res;
      })
      .catch(() => {
        loading.close();
      });
  }
  private getTypeData() {
    GetCepingTypeList(this).then((res: any) => {
      this.types = res;
    });
  }
  private mounted() {
    this.getTypeData();
    this.$store.commit("updateIfCompose", false);
    this.getLeftData().then(() => {
      // 如果没有选中项，默认选中第一个
      if (!this.patientFileFilter.thirdTab) {
        this.$store.commit(
          "updatePatientFileFilterRefrshTime",
          new Date().getTime()
        );
      } else {
        this.updateRightData();
      }
    });
  }
}
