








































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DsCarousel from "@/components/ds-carousel/ds-carousel.vue";
import DsEditor from "@/components/ds-editor/ds-editor.vue";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    DsCarousel,
    DsEditor,
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    this.zhuyuanTab = "出院记录";
  }
  // private zhuyuanType: any = ["病案首页", "入院记录", "首次病程录", "出院记录"];
  private zhuyuanType: any = ["出院记录"];
  private zhuyuanTab: any = "出院记录";
  private goEdit() {
    this.$router.push({
      path: "/main/workstation/patient-zhenliao-edit",
      query: {
        type: this.data["类别"],
        id: this.data.consultation_id,
      },
    });
    this.$store.commit("updateIfCompose", true);
  }
  private changeTab(item: any) {
    this.zhuyuanTab = item;
    setTimeout(() => {
      if (this.$refs.myEditor) {
        (this.$refs.myEditor as any).refresh();
      }
    }, 100);
  }
}
