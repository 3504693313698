

































import { Component, Vue, Prop } from "vue-property-decorator";
import Title from "./title.vue";
import SubTitle from "./title-sub.vue";
@Component({
  components: {
    Title,
    SubTitle,
  },
})
export default class Name extends Vue {
  @Prop()
  private content: any;
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  private timeType: any = "datetime";
  private format: any = "yyyy-MM-dd HH:mm:ss";
  private show_time: any = true;
  private get c() {
    const data = JSON.parse(JSON.stringify(this.content));
    if (data.format) {
      // 如果格式字段存在，需要根据格式判断是不是需要显示时间
      const index = data.format.indexOf("H");
      const index2 = data.format.indexOf("h");
      if (index !== -1 || index2 !== -1) {
        // 果里面含有H或者h则需要展示时间
        this.timeType = "datetime";
        this.format = data.format;
      } else {
        if (data.format.indexOf("d") !== -1) {
          // 如果找不到这是只有年月
          this.timeType = "date";
          this.format = data.format;
        } else if (data.format.indexOf("M") !== -1) {
          this.timeType = "month";
          this.format = data.format;
        } else if (data.format.indexOf("y") !== -1) {
          this.timeType = "year";
          this.format = data.format;
        }
      }
    } else {
      // 默认显示到秒
      this.timeType = "datetime";
      this.format = "yyyy-MM-dd HH:mm:ss";
    }
    return data;
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private data: any = {
    value: "",
  };
  private value: any = "";
  private valueChange(e: any) {
    if (this.source === "dialog") {
      this.$emit("updateDialog", this.c);
    } else {
      this.$emit("update", this.c);
    }
  }
  private valueBlur(e: any) {
    if (this.source === "dialog") {
      this.$emit("updateDialog", this.c);
    } else {
      this.$emit("update", this.c);
    }
  }
}
