























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  private isEdit: any;
  @Prop({
    type: Array,
    default: () => [],
  })
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    this.countTimeW();
  }
  private ifOverstep: any = false; // 是否溢出，用来控制上一个和下一个按钮是否显示
  private oneW: any = 140; // 单个时间轴的宽度
  private wrapW: any = 0;
  private get resizeTime() {
    return this.$store.state.resizeTime;
  }
  @Watch("resizeTime", { immediate: true })
  private resizeTimeChange() {
    if (this.resizeTime) {
      this.countTimeW();
    }
  }
  private read(url: any) {
    window.open(url);
  }
  private delOne(i: any) {
    this.$emit("del", i);
  }
  private countTimeW() {
    this.$nextTick(() => {
      const W: any = (this.$refs.carousel as any).offsetWidth - 30;
      if (this.data.length * this.oneW > W) {
        this.ifOverstep = true;
        // 计算数量,100为预留的上一个，下一个图标位置
        const num: any = Math.floor((W - 100) / this.oneW);
        this.wrapW = this.oneW * num;
      } else {
        this.wrapW = this.oneW * this.data.length;
        this.ifOverstep = false;
      }
      // 每次算完时间轴回到初始状态
      const ele: any = document.getElementById("carouselContent");
      ele.style.left = 0;
    });
  }
  private goBefore() {
    const ele: any = document.getElementById("carouselContent");
    const cLeft = ele.style.left ? Number(ele.style.left.split("px")[0]) : 0;
    if (cLeft > -this.oneW) {
      this.$message.warning("已经到底了");
      return;
    } else {
      ele.style.left = cLeft + this.oneW + "px";
    }
  }
  private goNext() {
    const maxL = this.oneW * this.data.length - this.wrapW;
    const ele: any = document.getElementById("carouselContent");
    const cLeft = ele.style.left ? Number(ele.style.left.split("px")[0]) : 0;
    if (-cLeft + this.oneW > maxL) {
      this.$message.warning("已经到底了");
      return;
    } else {
      ele.style.left = cLeft - this.oneW + "px";
    }
  }
}
