import Vue from 'vue'
import Component from 'vue-class-component'
@Component({})
export class zhibiaoRangeMixin extends Vue {
    private get rangeData() {
        return this.$store.state.zhibiaoRange;
    }
    getTrend(type: any, time: any, val: any, leixing?: any) {
        if (!val || !time) {
            return '正常';
        }
        let result: any = '';
        let d: any = [];
        if (type == '心率') {
            d = this.rangeData[time + '心率'];
        } else if (type == '血压') {
            d = this.rangeData[time][leixing];
        } else {
            d = this.rangeData[time];

        }
        if (val < d[0]) {
            result = '偏低'
        } else if (val > d[1]) {
            result = '偏高'
        } else {
            result = '正常'
        }
        return result;
    }
}