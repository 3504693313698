





















































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getCepingDetail, deleteJiangkangceping } from "@/request/doctor";
import {
  getSolutionList,
  getSolutionDetail,
  delSolution,
} from "@/request/custom";
import { getCurrentTime } from "@/utils/base";
import Read from "./read.vue";
import YundongRead from "./yundong-read.vue";
import Empty from "@/components/empty.vue";
import LeftList from "../../components/left-list/left-list.vue";
@Component({
  components: {
    Read,
    Empty,
    LeftList,
    YundongRead,
  },
})
export default class Name extends Vue {
  private leftNavList: any = [];
  private detail: any = {};
  private IfShowAdd: any = false;
  private addValue: any = "";
  private editData: any = {};
  private options: any = [];
  private disabledEndDate: any = this.endDate();
  private endDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        return time.getTime() > Date.now();
      },
    };
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private get user() {
    return this.$store.state.user || {};
  }
  private get configuration() {
    return this.$store.state.configuration;
  }
  private get patientFileFilter() {
    return this.$store.state.patientFileFilter;
  }
  private editTimeSure() {
    // 编辑后需要重新更新选中的数据
    this.getLeftData().then((res: any) => {
      res.forEach((ele: any) => {
        if (ele.content_id === this.patientFileFilter.thirdTab.content_id) {
          const d: any = JSON.parse(JSON.stringify(ele));
          this.$store.commit("updatePatientFileFilter", d);
          this.updateRightData();
        }
      });
    });
  }

  private delOne(e: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      solution_id: e.solution_id,
    };
    delSolution(this, params)
      .then((res: any) => {
        loading.close();
        this.$message.success("删除成功");
        this.getLeftData().then((res: any) => {
          let obj: any = JSON.parse(JSON.stringify(this.patientFileFilter));
          if (res.length > 0) {
            this.$store.commit(
              "updatePatientFileFilterRefrshTime",
              new Date().getTime()
            );
          } else {
            obj.thirdTab = "";
            this.$store.commit("updatePatientFileFilter", obj);
            this.detail = {};
          }
        });
      })
      .catch(() => {
        loading.close();
      });
  }
  private openAdd() {
    this.IfShowAdd = true;
    this.addValue = "自定义方案";
  }
  private addSure() {
    if (!this.addValue) {
      this.$message.warning("请选择方案类型！");
      return;
    }
    this.IfShowAdd = false;
    // this.$router.push({
    //   path: "/main/workstation/patient-ganyu-edit",
    // });
    this.$router.push({
      path: "/main/workstation/patient-ganyu-edit",
      query: {
        type: this.addValue,
      },
    });
    this.$store.commit("updateIfCompose", true);
  }
  private goEdit() {
    this.$router.push({
      path: "/main/workstation/patient-ganyu-edit",
      query: {
        id: this.detail.solution_id,
        type: this.detail["类别"] || "自定义方案",
      },
    });
    this.$store.commit("updateIfCompose", true);
  }
  private getLeftData() {
    const params: any = {
      patient_id: this.patient.patient_id,
    };
    return new Promise((resolve, reject) => {
      getSolutionList(this, params).then((res: any) => {
        this.leftNavList = res;
        resolve(res);
      });
    });
  }
  private updateRightData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      solution_id: this.patientFileFilter.thirdTab.solution_id,
    };
    getSolutionDetail(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.detail = res;
      })
      .catch(() => {
        loading.close();
      });
  }
  private mounted() {
    this.$store.commit("updateIfCompose", false);
    this.getLeftData().then(() => {
      // 如果没有选中项，默认选中第一个
      if (!this.patientFileFilter.thirdTab) {
        this.$store.commit(
          "updatePatientFileFilterRefrshTime",
          new Date().getTime()
        );
      } else {
        this.updateRightData();
      }
    });
  }
}
