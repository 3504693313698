































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Empty from "@/components/empty.vue";
import Chart from "../chart/chart.vue";
import SelectDuoxuan from "@/components/select/select-duoxuan.vue";
import TimeRange from "@/components/time-range/time-range.vue";
import Qushi from "../../components/qushi/qushi.vue";
import {
  getHuaxiangData,
  editHuaxiangData,
  saveWeixianyinsu,
  getYichang,
} from "@/request/custom";
import {
  getOptions,
  getWeixianOptions,
  GetCepingTypeList,
} from "@/request/doctor";
@Component({
  components: {
    Empty,
    Chart,
    SelectDuoxuan,
    TimeRange,
    Qushi,
  },
})
export default class Name extends Vue {
  private ifShowYichang: any = false;
  private ifShowQushi: any = false;
  private qushiData: any = {};
  private labelW: any = 120;
  private selectVal: any = [];
  private selectType: any = "";
  private ifShowSelect: any = false;
  private selectData: any = [];
  private yichangData: any = [];
  private data: any = {
    既往史: [],
    家族史: [],
    用药史: [],
    过敏史: [],
    生活方式: [],
    心血管病史: [],
    评估: {},
  };
  private chartLine: any = {};
  private types: any = [];
  private get patient() {
    return this.$store.state.patient;
  }
  private getTypeData() {
    GetCepingTypeList(this).then((res: any) => {
      this.types = [];
      res.forEach((ele: any) => {
        this.types.push({ id: ele });
      });
    });
  }
  private goReport(item: any) {
    this.$router.push({
      path: "/main/workstation/patient-pinggu-report",
      query: {
        id: item.assess_report_id,
      },
    });
  }
  private closeQushi() {
    this.ifShowQushi = false;
  }
  private goPatient() {
    let ifQianyue: any = false;
    this.$router.push({
      path: "/main/workstation/patient-edit-message",
      query: {
        patient_id: this.patient.patient_id,
        签约: ifQianyue,
      },
    });
  }
  private openYichang() {
    this.ifShowYichang = true;
  }
  private openQushi(item: any) {
    this.qushiData = item;
    this.ifShowQushi = true;
  }
  private openAdd(type: any) {
    if (type === "危险分层") {
      this.$message.warning("正在开发中");
    } else {
      this.$router.push({
        path: "/main/workstation/patient-pinggu-introduce",
        query: {
          type: type,
        },
      });
    }
  }
  private selectChange(e: any) {
    if (this.selectType == "危险因素") {
      let arr: any = [];
      e.forEach((ele: any) => {
        arr.push(ele.value);
      });
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params: any = {
        patient_id: this.patient.patient_id,
        index_names: arr,
        is_save: true,
      };
      saveWeixianyinsu(this, params, loading)
        .then((res: any) => {
          loading.close();
          this.getData();
          this.ifShowSelect = false;
        })
        .catch(() => {
          loading.close();
        });
    } else {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params: any = {
        patient_id: this.patient.patient_id,
        类型: this.selectType,
        data: e,
      };
      editHuaxiangData(this, params, loading)
        .then((res: any) => {
          loading.close();
          this.getData();
        })
        .catch(() => {
          loading.close();
        });
      // this.data[this.selectType] = e;
    }
    this.ifShowSelect = false;
  }
  private openSelect(val: any) {
    this.selectType = val;
    if (val == "危险因素") {
      const d: any = JSON.parse(JSON.stringify(this.data[val]));
      d.forEach((ele: any) => {
        ele.value = ele.index_name;
      });
      this.selectVal = d;
    } else {
      this.selectVal = JSON.parse(JSON.stringify(this.data[val]));
    }
    if (val == "生活方式") {
      this.labelW = 200;
    } else if (val == "心血管病史" || val == "危险因素") {
      this.labelW = 100;
    } else {
      this.labelW = 80;
    }
    const params: any = {
      data_type: val,
    };
    if (val == "危险因素") {
      getWeixianOptions(this).then((res: any) => {
        this.selectData = res;
        this.ifShowSelect = true;
      });
    } else {
      getOptions(this, params).then((res: any) => {
        this.selectData = res;
        this.ifShowSelect = true;
      });
    }
  }
  private getData() {
    this.getDayYichang();
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      patient_id: this.patient.patient_id,
    };
    getHuaxiangData(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data = res;
      })
      .catch(() => {
        loading.close();
      });
  }
  private getDayYichang() {
    const params: any = {
      params: {
        patient_id: this.patient.patient_id,
      },
    };
    getYichang(this, params).then((res: any) => {
      this.yichangData = res;
    });
  }
  private mounted() {
    this.getData();
    this.getTypeData();
  }
}
