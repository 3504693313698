import axios, { resHandle } from "@/plugins/axios";
/**
 * @description 获取移动设备列表
 */
const PostDeviceList = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/device_day_summary_list", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            loading.close();
            reject();
        });
    });
};
/**
 * @description 获取移动设备图表
 */
const PostDeviceChart = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/device_chart_data", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 获取移动设备天数据列表
 */
const PostDeviceDataList = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/device_data_list", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description Inbody分页查找
 */
const PostInbodyList = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/inbody/data_list", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description Inbody明细查找
 */
const PostInbodyDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/inbody/data_detail", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description K5分页查找
 */
const PostK5List = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/k5/data_list", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description K5明细查找
 */
const PostK5Detail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/k5/data_detail", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 随访包分页查找
 */
const PostSuifangbaoList = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/suifang/report_list", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 乐普健康随访包分页查找
 */
const PostLepuList = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/lepu/report_list", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 随访包明细查找
 */
const PostSuifangbaoDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/suifang/report_detail", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 获取病史资料数据
 */
const GetBingshi = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/user/patient_disease_doc_data", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 保存病史资料数据
 */
const SaveBingshi = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/user/save_patient_disease_doc", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 获取健康测评量表
 */
const GetCepingliangbiao = (vCom: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/project/assess_template_list").then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 乐普报告明细查找
 */
const PostLepuDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/lepu/report_detail", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 获取思科多超声仪列表
 */
const GetUsList = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/us/report_list", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 获取思科多超声仪详情
 */
const PostUsDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/us/report_detail", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 指标新增
 */
const PostZhibiao = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/save_warning", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 指标导出
 */
const ExportZhibiao = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/export_quota", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 指标导出
 */
const getNewQuotas = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/newest_warnings", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 运动类型查询
 */
const getYundongType = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/quota/activity_config").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 获取健康设备详情
 */
const getShebeiDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/shebei/weight_record_detail", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};

export {
    PostDeviceList, PostDeviceChart, PostDeviceDataList, PostInbodyList, PostInbodyDetail,
    PostK5List, PostK5Detail, GetBingshi, SaveBingshi, GetCepingliangbiao, PostSuifangbaoList,
    PostSuifangbaoDetail, PostLepuList, PostLepuDetail, GetUsList, PostUsDetail, PostZhibiao,
    ExportZhibiao, getNewQuotas, getYundongType, getShebeiDetail
}
