































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private name: any;
  @Prop()
  private data: any;

  private get patient() {
    return this.$store.state.patient;
  }
  private getName(url: any) {
    const arr: any = url.split("/");
    const name: any = arr[arr.length - 1].split(".");
    return name;
  }
  private read(url: any) {
    window.open(url);
  }
}
