var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"danganWrap"},[_c('div',{staticClass:"left"},[_c('LeftList',{attrs:{"leftNavList":_vm.leftNavList,"timeKey":"inspect_time","label":"inspect_name","keyValue":"inspect_id"},on:{"updateData":_vm.updateRightData,"delOne":_vm.delOne}}),_c('div',{staticClass:"defaultBtn addBtn",on:{"click":_vm.openAdd}},[_vm._v("+添加")])],1),_c('div',{staticClass:"right"},[(_vm.detail.inspect_name)?_c('div',{staticClass:"titleBox"},[_c('div',[_vm._v(_vm._s(_vm.detail.inspect_name))]),_c('div',{staticClass:"defaultBtn btn",on:{"click":_vm.goEdit}},[_vm._v("编辑")])]):_vm._e(),(_vm.detail.inspect_time)?_c('div',{staticClass:"time"},[_vm._v(" 报告时间："+_vm._s(_vm.detail.inspect_time)+" ")]):_vm._e(),(
        !_vm.detail.inspect_time ||
        (_vm.detail.pic_list &&
          _vm.detail.pic_list.length == 0 &&
          _vm.detail.inspect_info &&
          !_vm.detail.inspect_info.inspect_see &&
          !_vm.detail.inspect_info.inspect_result &&
          !_vm.detail.inspect_info.inspect_position &&
          !_vm.detail.inspect_info.inspect_category)
      )?_c('div',{staticClass:"emptyWrap"},[_c('Empty')],1):_c('Read',{attrs:{"data":_vm.detail}})],1),_c('el-dialog',{staticClass:"commonDialog addNameDialog",attrs:{"width":"500px","visible":_vm.IfShowAdd},on:{"update:visible":function($event){_vm.IfShowAdd=$event}}},[_c('button',{staticClass:"close",on:{"click":function($event){_vm.IfShowAdd = false}}}),_c('div',{staticClass:"header"},[_c('span',{staticClass:"title"},[_vm._v("添加检查项目")])]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"cell"},[_c('div',{staticClass:"label"},[_vm._v("检查项目名称：")]),_c('el-select',{attrs:{"size":"small","filterable":"","remote":"","reserve-keyword":"","placeholder":"请输入关键词","remote-method":_vm.remoteMethod,"value-key":"temp_id"},on:{"visible-change":_vm.focus},model:{value:(_vm.addValue),callback:function ($$v) {_vm.addValue=$$v},expression:"addValue"}},_vm._l((_vm.options),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.inspect_name,"value":item}})}),1)],1)]),_c('div',{staticClass:"control"},[_c('button',{staticClass:"cancelBtn",on:{"click":function($event){_vm.IfShowAdd = false}}},[_vm._v("取消")]),_c('button',{staticClass:"saveBtn",on:{"click":_vm.addSure}},[_vm._v("确定")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }