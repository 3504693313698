












































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Form as elForm } from "element-ui";
import { getCurrentTime } from "@/utils/base";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import {
  getZhenciList,
  getTemplateGroup,
  deleteTemplateGroup,
  getTemplateList,
  addTemplateGroup,
  deleteMenzhen,
  getFollowupList,
  getFollowupTems,
  getAllFollowupTems,
  EditFollowup,
  DelFollowup,
} from "@/request/doctor";
@Component({
  components: {},
})
export default class Name extends mixins(listMixin) {
  private jiegou: any = {};
  private list: any = [];
  private ifMuban: boolean = false;
  private ifLiangbiao: boolean = false;
  private ifMubanName: boolean = false;
  private cMuban: any = "";
  private data: any = {};
  private leftData: any = {};
  private templateGroup: any = [];
  private rightData: any = {};
  private liangbiaoTotal: any = 11;
  private leftSel: any = {};
  private rightSel: any = {};
  private liangbiaoSel: any = 0;
  private liangbiaoTem: any = this.configuration.liangbiaoTab;
  private leftCheckedAll: any = false;
  private rightCheckedAll: any = false;
  private nameData: any = {
    name: "",
  };
  private nameRules: any = {
    name: [{ validator: this.checkName, trigger: "blur" }],
  };
  private checkName(rule: any, value: any, callback: any) {
    if (!value) {
      return callback(new Error("请输入模板名称"));
    } else {
      if (value.length > 10) {
        callback(new Error("最多不能超过10个字"));
      } else {
        callback();
      }
    }
  }
  private get user() {
    return this.$store.state.user || {};
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private get configuration() {
    this.$store.state.configuration.liangbiaoTab.forEach((ele: any) => {
      this.jiegou[ele.label] = [];
    });
    this.leftData = JSON.parse(JSON.stringify(this.jiegou));
    this.rightData = JSON.parse(JSON.stringify(this.jiegou));
    this.leftSel = JSON.parse(JSON.stringify(this.jiegou));
    this.rightSel = JSON.parse(JSON.stringify(this.jiegou));
    return this.$store.state.configuration;
  }
  /**删除模板组 */
  private del() {
    const params: any = {
      data: {
        template_group_id: this.cMuban,
      },
    };
    deleteTemplateGroup(this, params).then((res: any) => {
      this.$message.success("删除成功");
      this.getTemplateGroup();
    });
  }
  private deleteOne(item: any) {
    this.$confirm("您确定要删除吗。", "删除", {
      customClass: "commonConfirm",
    })
      .then(() => {
        const params = {
          data: {
            followup_id: item.followup_id,
          },
        };
        DelFollowup(this, params).then((res: any) => {
          this.$message.success("删除成功");
          this.getList();
        });
      })
      .catch(() => {});
  }
  private isShowTitle(items: any) {
    let result = false;
    if (items) {
      items.forEach((ele: any) => {
        if (!ele.hide) {
          result = true;
        }
      });
    }
    return result;
  }
  private getNum(item: any) {
    let num = 0;
    this.liangbiaoTem.forEach((element: any) => {
      if (item[element.label]) {
        item[element.label].forEach((ele: any) => {
          if (!ele.hide) {
            num++;
          }
        });
      }
    });
    return num;
  }
  private addMenzhen() {
    this.ifMuban = true;
    this.getTemplateGroup();
  }
  private getTemplateGroup() {
    const params: any = {
      类别: "随访",
    };
    getTemplateGroup(this, params).then((res: any) => {
      this.templateGroup = res;
    });
  }
  private goRead(items: any) {
    this.$router.push({
      path: "/main/workstation/patient-menzhen-edit",
      query: {
        zhenci_id: items.zhenci_id,
        type: "read",
      },
    });
  }
  private goDetail(item: any) {
    this.$store.commit("updateIfCompose", true);
    this.$router.push({
      path: "/main/workstation/patient-suifang-edit",
      query: {
        id: item.followup_id,
      },
    });
  }
  private saveMuban() {
    let templates: any = [];
    this.liangbiaoTem.forEach((ele: any) => {
      this.rightData[ele.label].forEach((items: any) => {
        if (!items.hide) {
          templates.push({
            template_id: items.template_id,
          });
        }
      });
    });
    (this.$refs.nameForm as elForm).validate((isValid: boolean) => {
      if (isValid) {
        // 保存模板
        const params = {
          名称: this.nameData.name,
          templates: templates,
          类别: "随访",
        };
        addTemplateGroup(this, params).then((res: any) => {
          this.$message.success("保存模板成功");
          const params = {
            patient_id: this.patient.patient_id,
            tempalte_group_id: res,
            随访时间: getCurrentTime("yyyy-MM-dd HH:mm:ss"),
          };
          EditFollowup(this, params).then((res: any) => {
            this.$router.push({
              path: "/main/workstation/patient-suifang-edit",
              query: {
                id: res,
              },
            });
          });
        });
      }
    });
  }
  private openSave(type: any) {
    let templates: any = [];
    this.liangbiaoTem.forEach((ele: any) => {
      this.rightData[ele.label].forEach((items: any) => {
        if (!items.hide) {
          templates.push(items.template_id);
        }
      });
    });
    if (templates.length === 0) {
      this.$message.warning("请先添加量表到右边！");
      return;
    }
    if (type) {
      this.nameData.name = "";
      this.ifMuban = false;
      this.ifMubanName = true;
    } else {
      const params = {
        patient_id: this.patient.patient_id,
        template_ids: templates,
        随访时间: getCurrentTime("yyyy-MM-dd HH:mm:ss"),
      };
      EditFollowup(this, params).then((res: any) => {
        this.$router.push({
          path: "/main/workstation/patient-suifang-edit",
          query: {
            id: res,
          },
        });
      });
    }
  }
  private changeLeft(type: any, index: any) {
    this.leftData[type][index]["checked"] = true;
  }
  private changeRight(type: any, index: any) {
    this.rightData[type][index]["checked"] = true;
  }
  private leftSelAll() {
    this.leftSel = JSON.parse(JSON.stringify(this.jiegou));
    if (this.leftCheckedAll) {
      // 选中全部
      this.liangbiaoTem.forEach((item: any) => {
        if (this.leftData[item.label]) {
          this.leftData[item.label].forEach((ele: any) => {
            if (!ele.hide) {
              ele.checked = true;
              this.leftSel[item.label].push(ele);
            }
          });
        }
      });
    } else {
      // 取消全部选择
      this.liangbiaoTem.forEach((item: any) => {
        this.leftData[item.label].forEach((ele: any) => {
          ele.checked = false;
        });
      });
    }
  }
  private rightSelAll() {
    this.rightSel = JSON.parse(JSON.stringify(this.jiegou));
    if (this.rightCheckedAll) {
      // 选中全部
      this.liangbiaoTem.forEach((item: any) => {
        if (this.rightData[item.label]) {
          this.rightData[item.label].forEach((ele: any) => {
            if (!ele.hide) {
              ele.checked = true;
              this.rightSel[item.label].push(ele);
            }
          });
        }
      });
    } else {
      // 取消全部选择
      this.liangbiaoTem.forEach((item: any) => {
        this.rightData[item.label].forEach((ele: any) => {
          ele.checked = false;
        });
      });
    }
  }
  private toLeft() {
    this.liangbiaoTem.forEach((item: any) => {
      this.rightSel[item.label].forEach((ele: any) => {
        this.leftData[item.label].forEach((e: any) => {
          if (ele.template_id === e.template_id) {
            // 改变左边数据
            e.checked = false;
            e.hide = false;
            // 改变右边数据
            ele.hide = true;
            ele.checked = false;
          }
        });
      });
    });
    // 清除左边选中的
    this.rightSel = JSON.parse(JSON.stringify(this.jiegou));
    this.leftCheckedAll = false;
    this.rightCheckedAll = false;
    this.$forceUpdate();
  }
  private toRight() {
    this.liangbiaoTem.forEach((item: any) => {
      if (this.leftSel[item.label] && this.leftSel[item.label].length > 0) {
        this.leftSel[item.label].forEach((ele: any) => {
          this.rightData[item.label].forEach((e: any) => {
            if (ele.template_id === e.template_id) {
              // 改变右边数据
              e.checked = false;
              e.hide = false;
              // 改变左边数据
              ele.hide = true;
              ele.checked = false;
            }
          });
        });
      }
    });
    // 清除左边选中的
    this.leftSel = JSON.parse(JSON.stringify(this.jiegou));
    this.leftCheckedAll = false;
    this.rightCheckedAll = false;
    this.$forceUpdate();
  }
  getList() {
    const params = {
      patient_id: this.patient.patient_id,
      current_page: this.filters.current_page,
      page_count: this.filters.page_count,
    };
    getFollowupList(this, params).then((res: any) => {
      this.list = res.data;
      this.filters.total_count = res.total_count;
      this.filters.total_page = res.total_page;
      if (this.list.length === 0) {
        this.ifShowEmpty = true;
      } else {
        this.ifShowEmpty = false;
      }
    });
  }
  private selectMuban() {
    if (!this.cMuban) {
      this.ifMuban = false;
      this.ifLiangbiao = true;
      // 获取可选模板内容
      getAllFollowupTems(this).then((res: any) => {
        const data: any = [];
        if (res.length > 0) {
          res[0].temp_list.forEach((ele: any) => {
            ele["名称"] = ele.temp_name;
            ele["类别"] = "随访数据";
            ele.template_id = ele.temp_id;
            data.push(ele);
          });
        }
        this.data = {
          count: data.length,
          随访数据: data,
        };
        this.liangbiaoTem.forEach((item: any) => {
          if (this.data[item.label]) {
            this.data[item.label].forEach((ele: any) => {
              ele.checked = false;
            });
          }
        });
        this.leftData = JSON.parse(JSON.stringify(this.data));
        this.rightData = JSON.parse(JSON.stringify(this.data));
        // 右边全部为隐藏状态
        this.liangbiaoTem.forEach((item: any) => {
          if (this.rightData[item.label]) {
            this.rightData[item.label].forEach((ele: any) => {
              ele.hide = true;
            });
          }
        });
      });
      return;
      getFollowupTems(this, { 类别: "随访" }).then((res: any) => {
        this.data = res;
        this.liangbiaoTem.forEach((item: any) => {
          if (this.data[item.label]) {
            this.data[item.label].forEach((ele: any) => {
              ele.checked = false;
            });
          }
        });
        this.leftData = JSON.parse(JSON.stringify(this.data));
        this.rightData = JSON.parse(JSON.stringify(this.data));
        // 右边全部为隐藏状态
        this.liangbiaoTem.forEach((item: any) => {
          if (this.rightData[item.label]) {
            this.rightData[item.label].forEach((ele: any) => {
              ele.hide = true;
            });
          }
        });
      });
    } else {
      const params = {
        patient_id: this.patient.patient_id,
        tempalte_group_id: this.cMuban,
        随访时间: getCurrentTime("yyyy-MM-dd HH:mm:ss"),
      };
      EditFollowup(this, params).then((res: any) => {
        this.$router.push({
          path: "/main/workstation/patient-suifang-edit",
          query: {
            id: res,
          },
        });
      });
    }
  }
  mounted() {
    this.getList();
  }
}
