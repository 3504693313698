




























import { Component, Vue, Prop } from "vue-property-decorator";
import DsCarousel from "@/components/ds-carousel/ds-carousel.vue";
import DsEditor from "@/components/ds-editor/ds-editor.vue";
@Component({
  components: {
    DsCarousel,
    DsEditor,
  },
})
export default class Name extends Vue {
  @Prop()
  private data: any;
}
