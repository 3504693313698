
































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { PostDeviceDataList } from "@/request/workstation";
@Component({})
export default class Name extends mixins(listMixin) {
  @Prop()
  private id: any;
  @Prop()
  private readTime: any;
  @Watch("readTime", { immediate: true })
  private readTimeChange() {
    if (this.readTime) {
      this.getList();
    }
  }
  private data: any = [];
  private get patient() {
    return this.$store.state.patient;
  };
  getList() {
    const params: any = {
      patient_id: this.patient.patient_id,
      type: "脉搏",
      begin_date: this.id,
      end_date: this.id,
      current_page: this.filters.current_page,
      page_count: this.filters.page_count,
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostDeviceDataList(this, params)
      .then((data: any) => {
        loading.close();
        this.data = data.details;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.data.length > 0) {
          this.ifShowEmpty = false;
        } else {
          this.ifShowEmpty = true;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
}
