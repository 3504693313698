import { render, staticRenderFns } from "./shuzu-dongtai.vue?vue&type=template&id=18d565e0&scoped=true&"
import script from "./shuzu-dongtai.vue?vue&type=script&lang=ts&"
export * from "./shuzu-dongtai.vue?vue&type=script&lang=ts&"
import style0 from "./shuzu-dongtai.vue?vue&type=style&index=0&id=18d565e0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18d565e0",
  null
  
)

export default component.exports