












































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getControlData } from "../common";
import { clearVal } from "../common";
import Title from "./title.vue";
import SubTitle from "./title-sub.vue";
const TemplateTransfer = () => import("../template-transfer.vue");
@Component({
  components: {
    Title,
    SubTitle,
    TemplateTransfer,
  },
})
export default class Name extends Vue {
  @Prop()
  private content: any;
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  private get c() {
    return JSON.parse(JSON.stringify(this.content));
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private hasExtra(d: any) {
    let result = false;
    d.forEach((ele: any) => {
      if (ele.extra && ele.extra.length > 0) {
        result = true;
      }
    });
    return result;
  }
  private valueChange() {
    if (this.source === "dialog") {
      this.$emit("updateDialog", this.c);
    } else {
      this.$emit("update", this.c);
      this.changeHide();
    }
  }
  private clickDanbu(item: any) {
    if (!this.ifCompose) {
      return;
    }
    this.c.options.forEach((option: any) => {
      if (item.name === option.name) {
        option.value = !option.value;
      } else {
        option.value = false;
        if (item.extra) {
          item.extra.forEach((d: any) => {
            clearVal(d);
          });
        }
      }
      if (option.content) {
        option.content = "";
      }
    });
    this.valueChange();
  }
  /**
   * @description 题目联动
   */
  private changeHide() {
    // 有control字段才需要联动
    if (!this.c.control) {
      return;
    }
    const data = getControlData(this.c);
    this.$emit("changeHide", data);
  }
  private updateContent(e: any, index: any) {
    this.c.options[index]["extra"][e.index] = e.data;
    this.$emit("update", this.c);
  }
}
