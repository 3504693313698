





























































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { PostDeviceDataList } from "@/request/workstation";
import Export from "../export/export.vue";
@Component({
  components: {
    Export,
  },
})
export default class Name extends mixins(listMixin) {
  @Prop()
  private part: any;
  @Watch("part", { immediate: true })
  private partChange() {
    if (this.part === "心电") {
      this.readId = "";
      this.getList();
    }
  }
  @Prop()
  private updateListTime: any;
  @Watch("updateListTime", { immediate: true })
  private updateListTimeChange() {
    if (!this.updateListTime) {
      return;
    }
    if (this.part === "心电") {
      this.getList();
    }
  }
  private ifShowRead: any = false;
  private data: any = [];
  private readId: any = "";
  private ifShowTishi: any = false;
  private detail: any = {};

  private get patient() {
    return this.$store.state.patient;
  }
  private preView(url: any) {
    this.closeRead();
    window.open(url);
  }
  private goRead(item: any) {
    this.detail = {};
    this.detail = item["心电"];
    setTimeout(() => {
      this.ifShowRead = true;
    }, 100);
  }
  private openTishi() {
    this.ifShowTishi = true;
  }
  private refresh() {
    this.readId = "";
    this.getList();
  }
  private closeRead() {
    this.detail = {};
    this.ifShowRead = false;
  }
  getList() {
    this.data = [];
    const params: any = {
      patient_id: this.patient.patient_id,
      type: "心电",
      current_page: this.filters.current_page,
      page_count: this.filters.page_count,
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostDeviceDataList(this, params)
      .then((data: any) => {
        loading.close();
        this.data = data.details;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
}
