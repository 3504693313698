var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"read"},[(_vm.data['类别'] && _vm.data['就诊时间'])?_c('div',{staticClass:"titleBox"},[(_vm.data['类别'] == '门诊记录')?_c('div',[_vm._v(_vm._s(_vm.data["类别"]))]):_vm._e(),(_vm.data['类别'] == '住院记录')?_c('div',{staticClass:"topTab"},_vm._l((_vm.zhuyuanType),function(item,index){return _c('div',{key:index,class:{ active: _vm.zhuyuanTab == item },on:{"click":function($event){return _vm.changeTab(item)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e(),_c('div',{staticClass:"defaultBtn btn",on:{"click":_vm.goEdit}},[_vm._v("编辑")])]):_c('Empty'),(_vm.data['就诊时间'])?_c('div',{staticClass:"time"},[_vm._v(" 记录时间："+_vm._s(_vm.data["就诊时间"])+" ")]):_vm._e(),(_vm.data['类别'] == '门诊记录')?_c('div',[(_vm.data && _vm.data.pic_list && _vm.data.pic_list.length > 0)?_c('div',{staticClass:"carousel-wrap"},[_c('DsCarousel',{attrs:{"data":_vm.data.pic_list}})],1):_vm._e(),_c('div',{staticClass:"editor-wrap"},[(_vm.data && _vm.data.content)?_c('DsEditor',{attrs:{"readOnly":"","ifHideScroll":"","autoHeight":"","autoBottom":"24","data":_vm.data.content}}):_vm._e()],1),(!_vm.data.content && _vm.data.pic_list.length == 0)?_c('Empty'):_vm._e()],1):_vm._e(),(_vm.data['类别'] == '住院记录')?_c('div',[(
        _vm.data &&
        _vm.data[_vm.zhuyuanTab] &&
        _vm.data[_vm.zhuyuanTab].pic_list &&
        _vm.data[_vm.zhuyuanTab].pic_list.length > 0
      )?_c('div',{staticClass:"carousel-wrap"},[_c('DsCarousel',{attrs:{"data":_vm.data[_vm.zhuyuanTab].pic_list}})],1):_vm._e(),_c('div',{staticClass:"editor-wrap"},[(_vm.data && _vm.data[_vm.zhuyuanTab] && _vm.data[_vm.zhuyuanTab].content)?_c('DsEditor',{ref:"myEditor",attrs:{"readOnly":"","ifHideScroll":"","autoHeight":"","autoBottom":"24","data":_vm.data[_vm.zhuyuanTab].content}}):_vm._e()],1),(
        _vm.data[_vm.zhuyuanTab] &&
        !_vm.data[_vm.zhuyuanTab].content &&
        _vm.data[_vm.zhuyuanTab].pic_list.length == 0
      )?_c('Empty'):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }