/**
 * @description 压痛点选中文字
 * @param type，腰椎,正面
 * @returns 
 */
const getText = (data: any, type: any): any => {
    let showText: any = [];
    if (type == '腰椎') {
        const keyArr = ['棘旁压痛', '棘上压痛', 'L3横突', '臀上皮神经', '臀中肌', '臀小肌', '臀大肌', '髂后上棘', '梨状肌', '股方肌', '腓肠肌外侧头', '腓肠肌内侧头', '无压痛点']
        keyArr.forEach((ele: any, i: any) => {
            if (ele === '棘旁压痛' || ele === '棘上压痛') {
                if (data.value[ele] && data.value[ele]['L1']) {
                    showText.push(ele + 'L1')
                }
                if (data.value[ele] && data.value[ele]['L2']) {
                    showText.push(ele + 'L2')
                }
                if (data.value[ele] && data.value[ele]['L3']) {
                    showText.push(ele + 'L3')
                }
                if (data.value[ele] && data.value[ele]['L4']) {
                    showText.push(ele + 'L4')
                }
                if (data.value[ele] && data.value[ele]['L5']) {
                    showText.push(ele + 'L5')
                }
            } else {
                if (data.value[ele]) {
                    showText.push(ele)
                }
            }
        })
        return showText
    } else if (type == '正面') {
        const keyArr = ['锁骨上窝', '胸锁乳突肌锁骨附着处', '气户', '喙突', , '无压痛点']
        keyArr.forEach((ele: any, i: any) => {
            if (data.value[ele]) {
                showText.push(ele);
            }
        })
        return showText
    } else if (type == '侧面') {
        const keyArr = ['颞骨乳突', '乳突后下方', '1', '2', '3', '4', '5', '6', '7', '颈横纹', '无压痛点'];
        const TextArr = ['颞骨乳突', '乳突后下方', '横突1', '横突2', '横突3', '横突4', '横突5', '横突6', '横突7', '颈横纹', '无压痛点'];
        keyArr.forEach((ele: any, i: any) => {
            if (data.value[ele]) {
                showText.push(TextArr[i]);
            }
        })
        return showText
    } else if (type == '背面') {
        const keyArr = ['上项线', '寰枕间隙', '寰枢间隙', 'C2-3', 'C3-4', 'C4-5', 'C5-6', 'C6-7', 'C7', 'T1', 'T2', 'T3', '颈横纹',
            '内上角', '内缘', '外缘', '无压痛点'
        ]
        const TextArr = ['上项线(枕外隆凸两侧)', '寰枕间隙', '寰枢间隙', '关节间隙C2-3', '关节间隙C3-4', '关节间隙C4-5', '关节间隙C5-6', '关节间隙C6-7',
            '棘突侧方C7',
            '棘突侧方T1', '棘突侧方T2', '棘突侧方T3', '颈横纹', '内上角(肩胛骨)', '内缘(肩胛骨)', '外缘(肩胛骨)', '无压痛点'
        ]
        keyArr.forEach((ele: any, i: any) => {
            if (data.value[ele]) {
                showText.push(TextArr[i]);
            }
        })
        return showText
    } else if (type == '膝关节') {
        const keyArr = ['QT', 'LP', 'MP', 'PT', 'MJLA', 'SM', 'LJLA', 'SL', 'T', 'inside', 'outside']
        const TextArr = ['股四头肌肌腱(QT)', '外侧髌骨(LP)', '内侧髌骨(MP)', '髌骨肌腱(PT)', '内侧关节线区(MJLA)', '上内侧(SM)',
            '外侧关节线区(LJLA)',
            '上外侧(SL)',
            '胫骨(T)',
            '腓肠肌内侧头', '腓肠肌外侧头'
        ]
        keyArr.forEach((ele: any, i: any) => {
            if (ele === 'inside' || ele === 'outside') {
                if (data.value['R右侧'][ele]) {
                    showText.push(TextArr[i])
                }
            } else {
                if (data.value['L左侧'][ele]) {
                    showText.push(TextArr[i])
                }
            }
        })
        return showText
    }
}
/**
 * @description 控件题目是否未填
 * @param data{}// 控件数据
 * @returns 
 */
const IfEmpty = (data: any): any => {
    if (data.is_required) {
        if (data.type === "多选") {
            return data.value.length === 0;
        } else if (data.type === "计分单选" || data.type === "sf36计分单选") {
            return !data.value.name;
        } else if (data.type === "多选补充文字" || data.type === "单选补充文字") {
            let val = true;
            for (const opt of data["options"]) {
                if (opt.value) {
                    val = false;
                }
            }
            return val;
        } else if (data.type === "压痛点") {
            if (data.setting == '腰椎病压痛点左侧' || data.setting == '腰椎病压痛点右侧') {
                const d: any = getText(data, '腰椎');
                if (d.length > 0) {
                    return false
                } else {
                    return true
                }
            } else if (data.setting == '正面压痛点左侧' || data.setting == '正面压痛点右侧') {
                const d: any = getText(data, '正面');
                if (d.length > 0) {
                    return false
                } else {
                    return true
                }
            } else if (data.setting == '侧面压痛点左侧' || data.setting == '侧面压痛点右侧') {
                const d: any = getText(data, '侧面');
                if (d.length > 0) {
                    return false
                } else {
                    return true
                }
            } else if (data.setting == '背面压痛点左侧' || data.setting == '背面压痛点右侧') {
                const d: any = getText(data, '背面');
                if (d.length > 0) {
                    return false
                } else {
                    return true
                }
            } else if (data.setting == '膝关节压痛点左膝' || data.setting == '膝关节压痛点右膝') {
                const d: any = getText(data, '膝关节');
                if (d.length > 0) {
                    return false
                } else {
                    return true
                }
            }
        } else {
            return !data.value;
        }
    } else {
        return false
    }
}
/**
 * @description 生成题目联动数据
 * @param data{}
 * @returns 
 */
const getControlData = (data: any) => {
    if (data.type === "单选" || data.type === "下拉单选") {
        let index;
        data.options.forEach((ele: any, i: any) => {
            if (ele === data.value) {
                index = i;
            }
        });
        if (data.value) {
            data.control.index = [index];
        } else {
            data.control.index = [];
        }
    } else if (data.type === "计分单选" || data.type === "sf36计分单选") {
        let index;
        data.options.forEach((ele: any, i: any) => {
            if (ele.name === data.value.name) {
                index = i;
            }
        });
        if (data.value && data.value.name) {
            data.control.index = [index];
        } else {
            data.control.index = [];
        }
    } else if (data.type === "单选补充文字") {
        let index;
        let hasVal = false;
        data.options.forEach((ele: any, i: any) => {
            if (ele.value) {
                index = i;
                hasVal = true;
            }
        });
        if (hasVal) {
            data.control.index = [index];
        } else {
            data.control.index = [];
        }
    } else if (data.type === "多选") {
        const indexs: any = [];
        data.value.forEach((ele: any) => {
            data.options.forEach((item: any, i: any) => {
                if (ele === item) {
                    indexs.push(i);
                }
            });
        });
        if (indexs.length > 0) {
            data.control.index = indexs;
        } else {
            data.control.index = [];
        }
    } else if (data.type === "多选补充文字") {
        const indexs: any = [];
        data.options.forEach((ele: any, i: any) => {
            if (ele.value) {
                indexs.push(i);
            }
        });
        if (indexs.length > 0) {
            data.control.index = indexs;
        } else {
            data.control.index = [];
        }
    }
    return data;
}
/**
   * @description 隐藏或者显示某些相关题目
   */
const hideContent = (controlData: any, data: any) => {
    let arr: any = [];
    if (controlData.control.index.length === 0) {
        // 循环改值
        controlData.control.all.forEach((ele: any) => {
            const index = data.findIndex((val: any) => {
                return ele == val.id;
            });
            data[index]['hide'] = !controlData.control.hide;
            // 如果是隐藏需要清除值
            if (!controlData.control.hide) {
                clearVal(data[index]);
            }
        });
    } else {
        // 拼接数组
        controlData.control.index.forEach((ele: any) => {
            arr = arr.concat(controlData.control.list[ele]);
        });
        // 去重
        arr = Array.from(new Set(arr));
        controlData.control.all.forEach((ele: any) => {
            const index = data.findIndex((val: any) => {
                return ele == val.id;
            });
            const i = arr.findIndex((val: any) => {
                return ele === val;
            });
            if (i === -1) {
                // 找不到给hide相反的值
                data[index]['hide'] = !controlData.control.hide;
                // 如果是隐藏需要清除值
                if (!controlData.control.hide) {
                    clearVal(data[index]);
                }
            } else {
                // 找到给hide的值
                data[index]['hide'] = controlData.control.hide;
                // 如果是隐藏需要清除值
                if (controlData.control.hide) {
                    clearVal(data[index]);
                }
            }
        });
    }
    return data;
}
/**
 * @description 清除隐藏题目的value
 * @param data{}
 * @returns 
 */
const clearVal = (data: any) => {
    clearOne(data);
}
const clearOne = (data: any) => {
    if (data.type === "计分单选" || data.type === "sf36计分单选") {
        data.value = {};
    } else if (data.type === "单选补充文字" || data.type === "多选补充文字") {
        data.options.forEach((ele: any) => {
            ele.value = false;
            if (ele.content) {
                ele.content = "";
            }
            if (ele.extra) {
                ele.extra.forEach((d: any) => {
                    clearOne(d);
                });
            }
        });
    } else if (data.type === "多选" || data.type === "动态数组") {
        data.value = [];
    } else if (data.type === "表格") {
        // 表格不处理清空值
    } else {
        data.value = "";
    }
}
export {
    IfEmpty, getControlData, hideContent, clearVal, clearOne
};