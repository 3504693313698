
















































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  PostQuestion,
  PostQuestionRecords,
  GetCurrentUserData,
} from "./request";
import { IfEmpty, hideContent } from "./common";
const TemplateTransfer = () => import("./template-transfer.vue");
@Component({
  components: {
    TemplateTransfer,
  },
})
export default class Name extends Vue {
  @Prop()
  private data: any;
  @Prop()
  private index: any;
  private zhiyiText = "";
  private zhiyiElse = "";
  private zhiyiList: any[] = [];
  private dialogData: any = {};
  private xiugaiVisible: boolean = false;
  private get center() {
    return JSON.parse(localStorage.getItem("center") || "{}");
  }
  private get patient() {
    return JSON.parse(localStorage.getItem("patient") || "{}");
  }
  private get user() {
    return this.$store.state.user;
  }
  private get zhenci() {
    return this.$store.state.cZhenci;
  }
  private get cContent() {
    // this.getUserInfo();
    return this.$store.state.cContent;
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private get ifZhiyi() {
    return this.$store.state.ifZhiyi;
  }
  private getUserInfo() {
    const params: any = {
      params: {
        project_id: this.cContent.project_id,
      },
    };
    GetCurrentUserData(this, params).then((res: any) => {
      this.$store.commit("updateUser", res);
    });
  }
  private cancel() {
    this.xiugaiVisible = false;
  }
  private updateContent(e: any) {
    this.dialogData = e;
    return;
    this.cContent.data[this.index] = e;
    //计算BMI，量表顺序只能是身高，体重，BMI
    if (e.data.title === "身高") {
      const shengao = this.cContent.data[e.index].value / 100;
      const tizhong = this.cContent.data[e.index + 1].value;
      this.cContent.data[e.index + 2].value = (
        tizhong /
        (shengao * shengao)
      ).toFixed(2);
    } else if (e.data.title === "体重") {
      const shengao = this.cContent.data[e.index - 1].value / 100;
      const tizhong = this.cContent.data[e.index].value;
      this.cContent.data[e.index + 1].value = (
        tizhong /
        (shengao * shengao)
      ).toFixed(2);
    }
    this.$store.commit("updateCContent", this.cContent);
    this.$forceUpdate();
  }
  /**
   * @description 题目质疑按钮是否显示以及显示哪些
   */
  private ifShow(status: any) {
    if (!this.user.role) {
      return false;
    }
    let curShow = false;
    const role = this.user.role["名称"];
    if (role === "研究者" || role === "主要研究者") {
      // 是自己创建的患者
      if (this.user.user_id === this.patient.user_id) {
        // 诊次状态为质量审核员已打回
        if (this.zhenci.status === "质量审核员已打回") {
          // 题目状态为质量审核员已质疑或者已回复
          if (status === "质量审核员已质疑" || status === "已回复") {
            curShow = true;
          }
        }
      }
    } else if (role === "质量审核员") {
      // 诊次状态为待审核或者自己通过的或者监查员打回的
      if (this.zhenci.status === "待审核") {
        curShow = true;
      }
      if (
        this.zhenci.status === "质量审核员通过" &&
        this.user.user_id ===
          this.zhenci.audit_process[this.zhenci.audit_process.length - 1][
            "recheck_id"
          ]
      ) {
        curShow = true;
      }
      if (this.zhenci.status === "监查员已打回") {
        if (
          this.data.status !== "已完成" ||
          (this.data.status === "已完成" &&
            this.data.audit_process[this.data.audit_process.length - 1][
              "recheck_role"
            ] === "质量审核员")
        ) {
          curShow = true;
        }
      }
    } else if (role === "监查员") {
      // 诊次状态为待审核或者自己通过的
      if (this.zhenci.status === "分中心负责人通过") {
        curShow = true;
      }
      if (
        this.zhenci.status === "监查员通过" &&
        this.user.user_id ===
          this.zhenci.audit_process[this.zhenci.audit_process.length - 1][
            "recheck_id"
          ]
      ) {
        curShow = true;
      }
    } else if (role === "专病负责人" || role === "分中心负责人") {
      curShow = false;
    }
    return curShow;
  }
  /**
   * @description 打开质疑弹框
   */
  private open() {
    const role = this.user.role["名称"];
    if (role === "质量审核员" || role === "监查员") {
      if (!this.ifZhiyi) {
        this.$message.error("请点击质疑按钮开始质疑！");
        return;
      }
    }
    this.zhiyiText = "";
    this.zhiyiElse = "";
    this.dialogData = JSON.parse(JSON.stringify(this.data));
    this.xiugaiVisible = true;
    this.getQuestionRecords();
  }
  /**
   * @description 问题弹框确定
   */
  private sureXiugai() {
    const role = this.user.role["名称"];
    if (!this.zhiyiText) {
      this.$message.error("请先选择一个选项！");
      return;
    }
    let data: any = {};
    // 如果是必填项必须要填写
    if (role === "研究者" || role === "主要研究者") {
      if (this.dialogData.is_required) {
        const weitian = IfEmpty(this.dialogData);
        if (weitian) {
          // 弹提示哪个未填
          this.$message.warning(this.dialogData.title + "为必填项！");
          return;
        }
      }
      data = JSON.parse(JSON.stringify(this.dialogData));
      data.status = "已回复";
    } else if (role === "质量审核员") {
      data = JSON.parse(JSON.stringify(this.data));
      if (this.zhiyiText === "内容正确，通过") {
        data.status = "已完成";
      } else {
        data.status = "质量审核员已质疑";
      }
    } else if (role === "监查员") {
      data = JSON.parse(JSON.stringify(this.data));
      if (this.zhiyiText === "内容正确，通过") {
        data.status = "已完成";
      } else {
        data.status = "监查员已质疑";
      }
    }
    // 修改质疑过程
    const process = {
      recheck_id: this.user.user_id,
      recheck_name: this.user["姓名"],
      recheck_role: this.user.role["名称"],
      status: data.status,
      check_time: this.getCurrTime(),
    };
    if (!data.audit_process) {
      data.audit_process = [];
    }
    data.audit_process.push(process);
    const d: any = JSON.parse(JSON.stringify(this.cContent));
    d.data[this.index] = data;
    this.$store.commit("updateCContent", d);

    // 关闭弹框
    this.cancel();
    // 刷新数据
    const params = {
      content: this.cContent.data,
      text: this.zhiyiText,
      topic_id: this.data.id,
      topic_name: this.data.title,
      patient_id: this.patient.patient_id,
      project_id: this.center.project_id,
      zhenci_id: this.zhenci.zhenci_id,
      template_id: this.cContent.template_id,
    };
    if (this.zhiyiText === "不修改答案") {
      params.text = "不修改答案,原因" + this.zhiyiElse;
    } else if (this.zhiyiText === "其他") {
      params.text = this.zhiyiElse;
    }
    PostQuestion(this, params).then((res: any) => {
      this.cancel();
      this.$store.commit("updateCContent", this.cContent);
    });
  }
  private getCurrTime() {
    const now: any = new Date();
    const year: any = now.getFullYear(); // 得到年份
    let month: any = now.getMonth(); // 得到月份
    let date: any = now.getDate(); // 得到日期
    const day: any = now.getDay(); // 得到周几
    let hour: any = now.getHours(); // 得到小时
    let minu: any = now.getMinutes(); // 得到分钟
    let sec: any = now.getSeconds(); // 得到秒
    let MS: any = now.getMilliseconds(); // 获取毫秒
    let week: any;
    month = month + 1;
    if (month < 10) {
      month = "0" + month;
    }
    if (date < 10) {
      date = "0" + date;
    }
    if (hour < 10) {
      hour = "0" + hour;
    }
    if (minu < 10) {
      minu = "0" + minu;
    }
    if (sec < 10) {
      sec = "0" + sec;
    }
    if (MS < 100) {
      MS = "0" + MS;
    }
    const arrweek = new Array(
      "星期日",
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六"
    );
    week = arrweek[day];
    let time = "";
    time =
      year + "-" + month + "-" + date + " " + hour + ":" + minu + ":" + sec;
    return time;
  }
  private change() {
    this.zhiyiElse = "";
    if (
      this.user.role["名称"] &&
      (this.user.role["名称"] === "研究者" ||
        this.user.role["名称"] === "主要研究者")
    ) {
      this.dialogData = JSON.parse(JSON.stringify(this.data));
    }
  }
  /**
   * @description 获取题目记录接口
   */
  private getQuestionRecords() {
    const params = {
      patient_id: this.patient.patient_id,
      zhenci_id: this.zhenci.zhenci_id,
      topic_id: this.data.id,
      project_id: this.center.project_id,
    };
    PostQuestionRecords(this, params).then((res: any) => {
      this.zhiyiList = res;
    });
  }
}
