



























































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Xinlv from "./components/xinlv/xinlv.vue";
import Xindian from "./components/xindian/xindian.vue";
import Xueyang from "./components/xueyang/xueyang.vue";
import Xueya from "./components/xueya/xueya.vue";
import Tiwen from "./components/tiwen/tiwen.vue";
import Maibo from "./components/maibo/maibo.vue";
import Xuetang from "./components/xuetang/xuetang.vue";
import Tizhong from "./components/tizhong/tizhong.vue";
import Yundong from "./components/yundong/yundong.vue";
import TopSelect from "./components/top-select/top-select.vue";
import { getQuotaRange, saveZhibiao } from "@/request/custom";
import { patientDetail } from "@/request/doctor";
@Component({
  components: {
    TopSelect,
    Xinlv,
    Xindian,
    Xueyang,
    Xueya,
    Tiwen,
    Maibo,
    Xuetang,
    Tizhong,
    Yundong,
  },
})
export default class Name extends Vue {
  @Prop()
  private leftTabChangeTime: any;
  private updateListTime: any = "";
  private ranges: any = {};
  private ifShowRange: any = false;
  private ifShowJiance: any = false;
  private jianceData: any = [];
  private leftTree: any = [];
  private currentTree: any = "";
  private get patient() {
    return this.$store.state.patient;
  }
  private get user() {
    return this.$store.state.user || {};
  }
  private get configuration() {
    return this.$store.state.configuration;
  }
  private openJiance() {
    this.jianceData = JSON.parse(JSON.stringify(this.patient.monitor_items));
    this.ifShowJiance = true;
  }
  private selectZhibiao(val: any) {
    const index = this.jianceData.indexOf(val);
    if (index !== -1) {
      this.jianceData.splice(index, 1);
    } else {
      this.jianceData.push(val);
    }
  }
  private jianceSure() {
    const params: any = {
      patient_id: this.patient.patient_id,
      monitor_items: this.jianceData,
    };
    saveZhibiao(this, params).then((res: any) => {
      this.$message.success("保存成功");
      this.ifShowJiance = false;
      const params = {
        patient_id: this.patient.patient_id,
        source: "pc医生",
      };
      patientDetail(this, params).then((res: any) => {
        this.$store.commit("updatePatient", res);
      });
    });
  }
  private updateList() {
    this.updateListTime = new Date().getTime();
  }
  private refresh() {
    if (this.currentTree === "心率") {
      (this.$refs.xinlv as any).refresh();
    } else if (this.currentTree === "心电") {
      (this.$refs.xindian as any).refresh();
    } else if (this.currentTree === "血氧饱和度") {
      (this.$refs.xueyang as any).refresh();
    } else if (this.currentTree === "血压") {
      (this.$refs.xueya as any).refresh();
    } else if (this.currentTree === "体温") {
      (this.$refs.tiwen as any).refresh();
    } else if (this.currentTree === "脉搏") {
      (this.$refs.maibo as any).refresh();
    } else if (this.currentTree === "血糖") {
      (this.$refs.xuetang as any).refresh();
    }
  }
  private changePart(item: any) {
    this.currentTree = item.value;
    this.updateRange();
  }
  private updateRange() {
    let obj: any = {};
    const params: any = {
      params: {
        patient_id: this.patient.patient_id,
        type: this.currentTree,
      },
    };
    getQuotaRange(this, params).then((res: any) => {
      if (this.currentTree == "血糖") {
        obj = {
          空腹: this.getRangeData(this.currentTree, "空腹", res),
          早餐后: this.getRangeData(this.currentTree, "早餐后", res),
          午餐前: this.getRangeData(this.currentTree, "午餐前", res),
          午餐后: this.getRangeData(this.currentTree, "午餐后", res),
          晚餐前: this.getRangeData(this.currentTree, "晚餐前", res),
          晚餐后: this.getRangeData(this.currentTree, "晚餐后", res),
          睡前: this.getRangeData(this.currentTree, "睡前", res),
          凌晨: this.getRangeData(this.currentTree, "凌晨", res),
          随机: this.getRangeData(this.currentTree, "随机", res),
        };
      } else if (this.currentTree == "血压") {
        obj = {
          静息: {
            收缩压: this.getRangeData(this.currentTree, "静息收缩压", res),
            舒张压: this.getRangeData(this.currentTree, "静息舒张压", res),
          },
          吸烟: {
            收缩压: this.getRangeData(this.currentTree, "吸烟收缩压", res),
            舒张压: this.getRangeData(this.currentTree, "吸烟舒张压", res),
          },
          进食: {
            收缩压: this.getRangeData(this.currentTree, "进食收缩压", res),
            舒张压: this.getRangeData(this.currentTree, "进食舒张压", res),
          },
          饮酒: {
            收缩压: this.getRangeData(this.currentTree, "饮酒收缩压", res),
            舒张压: this.getRangeData(this.currentTree, "饮酒舒张压", res),
          },
          喝咖啡: {
            收缩压: this.getRangeData(this.currentTree, "喝咖啡收缩压", res),
            舒张压: this.getRangeData(this.currentTree, "喝咖啡舒张压", res),
          },
          剧烈运动: {
            收缩压: this.getRangeData(this.currentTree, "剧烈运动收缩压", res),
            舒张压: this.getRangeData(this.currentTree, "剧烈运动舒张压", res),
          },
          起床后: {
            收缩压: this.getRangeData(this.currentTree, "起床后收缩压", res),
            舒张压: this.getRangeData(this.currentTree, "起床后舒张压", res),
          },
          睡前: {
            收缩压: this.getRangeData(this.currentTree, "睡前收缩压", res),
            舒张压: this.getRangeData(this.currentTree, "睡前舒张压", res),
          },
        };
      } else if (this.currentTree == "心率") {
        obj = {
          静息心率: this.getRangeData(this.currentTree, "静息心率", res),
          运动心率: this.getRangeData(this.currentTree, "运动心率", res),
        };
      } else if (this.currentTree == "体重") {
        obj = {
          BMI: this.getRangeData(this.currentTree, "BMI", res),
        };
      } else if (this.currentTree == "血氧饱和度") {
        obj = {
          血氧饱和度: this.getRangeData(this.currentTree, "血氧饱和度", res),
        };
      } else if (this.currentTree == "体温") {
        obj = {
          体温: this.getRangeData(this.currentTree, "体温", res),
        };
      }
      this.$store.commit("updateZhibiaoRange", obj);
    });
  }
  private getRangeData(type: any, key: any, val: any) {
    return [val[key].range[0], val[key].range[1], val[key].unit];
  }
  mounted() {
    this.configuration.danganNavs.forEach((ele: any) => {
      if (ele.value === "居家监测") {
        this.leftTree = ele.children;
        this.currentTree = this.leftTree[0].value;
        //获取正常值范围
        this.updateRange();
      }
    });
  }
}
