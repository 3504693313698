

































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import echarts from "echarts";
import DsCarousel from "@/components/ds-carousel/ds-carousel.vue";
import TimeRange from "@/components/time-range/time-range.vue";
import Empty from "@/components/empty.vue";
import Qushi from "../../components/qushi/qushi.vue";
@Component({
  components: {
    DsCarousel,
    TimeRange,
    Empty,
    Qushi,
  },
})
export default class Name extends Vue {
  @Prop()
  private data: any;
  private ifShowQushi: any = false;
  private IfShowEcharts: any = false;
  private filter: any = {};
  private currentCell: any = {};
  private qushiData: any = {};

  private get resizeTime() {
    return this.$store.state.resizeTime;
  }
  private getResult(item: any) {
    if (!item.result) {
      return "";
    }
    const val = Number(item.result);
    const min = Number(item.interval.split("~")[0]);
    const max = Number(item.interval.split("~")[1]);
    let result: any = "正常";
    if (val < min) {
      result = "偏低";
    } else if (val > max) {
      result = "偏高";
    }
    return result;
  }
  private openQushi(item: any) {
    this.qushiData = item;
    this.ifShowQushi = true;
  }
  private closeQushi() {
    this.ifShowQushi = false;
  }
}
