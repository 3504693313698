var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navCon"},[_vm._l((_vm.leftNavList),function(items,index){return _c('div',{key:index},[_c('div',{staticClass:"cell",class:{
        active:
          _vm.patientFileFilter.thirdTab &&
          _vm.patientFileFilter.thirdTab[_vm.label] &&
          _vm.patientFileFilter.thirdTab[_vm.keyValue] == items[_vm.keyValue],
      },on:{"click":function($event){return _vm.changeLeft(items)}}},[_c('div',{staticClass:"leftName"},[_c('div',{staticClass:"name",attrs:{"title":items[_vm.label]}},[_c('div',{staticClass:"oneline"},[_vm._v(_vm._s(items[_vm.label]))]),(items['提交时间'])?_c('img',{attrs:{"src":"/workstation/提交.png","alt":""}}):_vm._e()]),_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(items[_vm.timeKey])+" ")])]),(!_vm.hideTool)?_c('div',{staticClass:"rightBtn"},[_c('el-dropdown',{on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"el-icon-more"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":{
                value: items,
                type: '删除',
              }}},[_vm._v("删除")])],1)],1)],1):_vm._e()])])}),(_vm.leftNavList && _vm.leftNavList.length == 0)?_c('Empty',{staticClass:"navEmpty"}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }