












































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Empty from "@/components/empty.vue";
import { getCurrentTime } from "@/utils/base";
import { getQuotaRange, saveQuotaRange } from "@/request/custom";
import { editPatient, patientDetail } from "@/request/doctor";
import {
  getNewQuotas,
  PostDeviceChart,
  PostZhibiao,
  getYundongType,
} from "@/request/workstation";
import Export from "../export/export.vue";
import { UploadFile } from "@/components/template-transfer-pc/template-transfer/request";
@Component({
  components: {
    Empty,
    Export,
  },
})
export default class Name extends Vue {
  @Prop()
  private type: any;
  @Watch("type", { immediate: true })
  private typeChange() {
    if (this.type == "血糖") {
      this.options = [
        "空腹",
        "早餐后",
        "午餐前",
        "午餐后",
        "晚餐前",
        "晚餐后",
        "睡前",
        "凌晨",
        "随机",
      ];
      this.cOpt = "";
    } else if (this.type == "血压") {
      this.options = [
        "静息",
        "吸烟",
        "进食",
        "饮酒",
        "喝咖啡",
        "剧烈运动",
        "起床后",
        "睡前",
      ];
      this.cOpt = "";
    } else if (this.type == "心电") {
      this.options = ["单导", "6导", "12导"];
      this.cOpt = "";
    } else if (this.type == "脉搏") {
      this.options = [
        "静息",
        "吸烟",
        "饮酒",
        "喝咖啡",
        "起床后",
        "睡前",
        "剧烈运动",
        "进食",
      ];
      this.cOpt = "";
    } else if (this.type == "心率") {
      this.options = ["静息", "运动"];
      this.cOpt = "";
    }
    if (this.type !== "心电") {
      this.reset();
    }
  }
  @Prop()
  private leftTabChangeTime: any;
  @Watch("leftTabChangeTime", { immediate: true })
  private leftTabChangeTimeChange() {
    if (this.leftTabChangeTime) {
      this.showEcharts = false;
      setTimeout(() => {
        this.showEcharts = true;
      }, 100);
    }
  }
  private ifShowJuli: any = false; // 是否显示运动的距离输入框
  private cOpt: any = "";
  private options: any = [];
  private ifShowAdd: any = false;
  private addData: any = {};
  private currentPart: any = "日";
  private showEcharts: any = true;
  private startTimeStamp: any = "";
  private endTimeStamp: any = "";
  private time: any = "";
  private chartData: any = {};
  private chartLine: any = {};
  private ifShowExport: any = false;
  private limitedNumber: any = 1;
  private ifShowRange: any = false;
  private rangeNav: any = [];
  private rangeData: any = {};
  private ifShowHeight: any = false;
  private heightData: any = {};
  private hours: any = [];
  private minutes: any = [];
  private yundongOptions: any = [];
  private disabledStartDate: any = this.beginDate();
  private beginDate() {
    return {
      disabledDate(time: any) {
        return time.getTime() > Date.now();
      },
    };
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private get user() {
    return this.$store.state.user || {};
  }
  private get zhibiaoRange() {
    this.rangeNav = [];
    const d: any = this.$store.state.zhibiaoRange;
    for (const key in d) {
      if (Object.prototype.hasOwnProperty.call(d, key)) {
        this.rangeNav.push(key);
      }
    }
    return this.$store.state.zhibiaoRange;
  }
  private yundongTypeChange(e: any) {
    if (!e || e.length == 0) {
      this.addData.unit = "";
      return;
    }
    let d: any = {};
    this.yundongOptions.forEach((element: any) => {
      if (element.name == e[0]) {
        element.children.forEach((ele: any) => {
          if (ele.name == e[1]) {
            d = ele;
          }
        });
      }
    });
    this.ifShowJuli = d["距离"];
    this.addData["距离"] = null;
    this.addData["距离单位"] = d.unit;
  }
  private reset() {
    this.currentPart = "日";
    // 选择有数据的一天，如果没有数据显示当前时间
    this.getNewst().then((res: any) => {
      const d = res[this.type];
      if (d && d["测量时间"]) {
        this.time = this.getDate(d["测量时间"]);
      } else {
        this.time = this.getDate();
      }
      // 更新折线图
      this.drawchart();
    });
  }
  private openRange() {
    this.rangeData = JSON.parse(JSON.stringify(this.zhibiaoRange));
    this.ifShowRange = true;
  }
  private rangeSure() {
    if (this.type == "血压") {
      for (const key in this.rangeData) {
        if (Object.prototype.hasOwnProperty.call(this.rangeData, key)) {
          const d = this.rangeData[key];
          if (
            d["收缩压"][0] > d["收缩压"][1] ||
            d["收缩压"][0] == d["收缩压"][1]
          ) {
            this.$message.warning(key + "收缩压的最小值必须小于最大值！");
            return;
          }
          if (
            d["舒张压"][0] > d["舒张压"][1] ||
            d["舒张压"][0] == d["舒张压"][1]
          ) {
            this.$message.warning(key + "舒张压的最小值必须小于最大值！");
            return;
          }
        }
      }
    } else {
      for (const key in this.rangeData) {
        if (Object.prototype.hasOwnProperty.call(this.rangeData, key)) {
          const ele = this.rangeData[key];
          if (ele[0] > ele[1] || ele[0] == ele[1]) {
            this.$message.warning(key + "的最小值必须小于最大值！");
            return;
          }
        }
      }
    }
    // 处理数据，处理成后端需要的数据格式
    const keyArr: any = {
      血糖: [
        "空腹",
        "早餐后",
        "午餐前",
        "午餐后",
        "晚餐前",
        "晚餐后",
        "睡前",
        "凌晨",
        "随机",
      ],
      血压: [
        "静息收缩压",
        "静息舒张压",
        "吸烟收缩压",
        "吸烟舒张压",
        "进食收缩压",
        "进食舒张压",
        "饮酒收缩压",
        "饮酒舒张压",
        "喝咖啡收缩压",
        "喝咖啡舒张压",
        "剧烈运动收缩压",
        "剧烈运动舒张压",
        "起床后收缩压",
        "起床后舒张压",
        "睡前收缩压",
        "睡前舒张压",
      ],
      心率: ["静息心率", "运动心率"],
      体重: ["BMI"],
      血氧饱和度: ["血氧饱和度"],
      体温: ["体温"],
    };
    let d: any = {};
    keyArr[this.type].forEach((ele: any) => {
      const obj = {};
      if (this.type == "血压") {
        const key1: any = ele.substring(0, ele.length - 3);
        const key2: any = ele.substring(ele.length - 3);
        d[ele] = {
          range: [this.rangeData[key1][key2][0], this.rangeData[key1][key2][1]],
          unit: this.rangeData[key1][key2][2],
        };
      } else {
        d[ele] = {
          range: [this.rangeData[ele][0], this.rangeData[ele][1]],
          unit: this.rangeData[ele][2],
        };
      }
    });

    // 保存修改的正常值范围
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      patient_id: this.patient.patient_id,
    };
    params[this.type] = d;
    saveQuotaRange(this, params, loading)
      .then((res: any) => {
        this.$store.commit(
          "updateZhibiaoRange",
          JSON.parse(JSON.stringify(this.rangeData))
        );
        this.ifShowRange = false;
        // 刷新时间和图数据
        if (this.type !== "心电") {
          this.reset();
        }
        // 刷新列表
        this.$emit("updateList");
        loading.close();
      })
      .catch(() => {
        loading.close();
      });
  }
  private heightSure() {
    if (!this.heightData.height) {
      this.$message.warning("请输入身高");
      return;
    }
    const data = JSON.parse(JSON.stringify(this.patient));
    data["身高"] = this.heightData.height;
    editPatient(this, data).then((d: any) => {
      const params = {
        patient_id: d.patient_id,
        source: "pc医生",
      };
      patientDetail(this, params).then((res: any) => {
        this.$store.commit("updatePatient", res);
        this.ifShowHeight = false;
        this.openAdd();
      });
    });
  }
  /**
   * @description 超出数量时提示
   */
  private exceedHelp() {
    this.$message.warning("该项最多只能上传" + this.limitedNumber + "个文件");
  }
  /**
   * @description 上传
   */
  private upload(d: any) {
    const fileObj = d.file;
    // FormData 对象
    const form = new FormData();
    // 文件对象
    form.append("type", "file");
    form.append("file", fileObj);
    UploadFile(this, form).then((data: any) => {
      this.addData["心电图"] = data.full_file_url;
      this.$forceUpdate();
    });
  }
  /**
   * @description 图片预览
   */
  private handlePictureCardPreview(url: any) {
    window.open(url);
  }
  /**
   * @description 删除图片
   */
  private del() {
    this.addData["心电图"] = "";
    this.$forceUpdate();
  }
  private changeOpt(item: any) {
    this.addData["类别"] = item;
    this.$forceUpdate();
  }
  private addSure() {
    if (this.type == "血糖" && !this.addData["血糖"]) {
      this.$message.warning("请填写血糖！");
      return;
    }
    if (this.type == "心率" && !this.addData["心率"]) {
      this.$message.warning("请填写心率！");
      return;
    }
    if (this.type == "心电" && !this.addData["心电图"]) {
      this.$message.warning("请上传心电图！");
      return;
    }
    if (this.type == "血氧饱和度" && !this.addData["血氧饱和度"]) {
      this.$message.warning("请填写血氧饱和度！");
      return;
    }
    if (this.type == "脉搏" && !this.addData["脉搏"]) {
      this.$message.warning("请填写脉搏！");
      return;
    }
    if (this.type == "体温" && !this.addData["体温"]) {
      this.$message.warning("请填写体温！");
      return;
    }
    if (this.type == "体重" && !this.addData["体重"]) {
      this.$message.warning("请填写体重！");
      return;
    }
    if (this.type == "血压" && !this.addData["收缩压"]) {
      this.$message.warning("请填写收缩压！");
      return;
    }
    if (this.type == "血压" && !this.addData["舒张压"]) {
      this.$message.warning("请填写舒张压！");
      return;
    }
    if (
      (this.type == "血糖" || this.type == "心率") &&
      !this.addData["测量时间"]
    ) {
      this.$message.warning("请选择测量时间！");
      return;
    }
    if (this.type == "血糖" && !this.addData["类别"]) {
      this.$message.warning("请选择测量时间段！");
      return;
    }
    if (this.type == "心电" && !this.addData["类别"]) {
      this.$message.warning("请选择心电图类别！");
      return;
    }
    if (this.type == "脉搏" && !this.addData["类别"]) {
      this.$message.warning("请选择测量前活动！");
      return;
    }
    if (this.type == "血压" && !this.addData["类别"]) {
      this.$message.warning("请选择测量前活动！");
      return;
    }
    if (this.type == "心率" && !this.addData["类别"]) {
      this.$message.warning("请选择测量前活动！");
      return;
    }
    if (
      this.type == "运动" &&
      (!this.addData["运动类型"] || !this.addData["运动类型"][1])
    ) {
      this.$message.warning("请选择运动类型！");
      return;
    }
    if (this.type == "运动" && !this.addData["测量时间"]) {
      this.$message.warning("请选择开始时间！");
      return;
    }
    if (this.type == "运动" && !this.addData["运动时长"]) {
      this.$message.warning("请选择运动时长！");
      return;
    }
    const params: any = {
      patient_id: this.patient.patient_id,
      来源: "pc医生",
      type: this.type,
      测量时间: this.addData["测量时间"],
    };
    if (this.type == "血糖") {
      params["血糖"] = this.addData["血糖"];
      params["血糖时间"] = this.addData["类别"];
    }
    if (this.type == "心率") {
      params["心率"] = this.addData["心率"];
      params["心率时间"] = this.addData["类别"];
    }
    if (this.type == "心电") {
      const obj: any = {
        value: [],
      };
      obj.value = [{ url: this.addData["心电图"] }];
      params["心电"] = obj;
      params["心电类别"] = this.addData["类别"];
    }
    if (this.type == "血氧饱和度") {
      params["血氧饱和度"] = this.addData["血氧饱和度"];
    }
    if (this.type == "脉搏") {
      params["脉搏"] = this.addData["脉搏"];
      params["脉搏时间"] = this.addData["类别"];
    }
    if (this.type == "体温") {
      params["体温"] = this.addData["体温"];
    }
    if (this.type == "血压") {
      params["舒张压"] = this.addData["舒张压"];
      params["收缩压"] = this.addData["收缩压"];
      params["血压活动"] = this.addData["类别"];
    }
    if (this.type == "体重") {
      params["体重"] = this.addData["体重"];
    }
    if (this.type == "运动") {
      params["运动类型"] = this.addData["运动类型"][1];
      params["运动时长"] = this.addData["运动时长"];
      params["平均心率"] = this.addData["平均心率"];
      params["最大心率"] = this.addData["最大心率"];
      params["距离"] = this.addData["距离"];
      params["距离单位"] = this.addData["距离单位"];
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostZhibiao(this, params, loading).then((res: any) => {
      loading.close();
      this.ifShowAdd = false;
      this.$message.warning("添加成功");
      // 刷新时间和图数据
      if (this.type !== "心电") {
        this.reset();
      }
      // 刷新列表
      this.$emit("updateList");
    });
  }
  private changePart(item: any) {
    this.currentPart = item;
    // 选择有数据的一天，如果没有数据显示当前时间
    this.getNewst().then((res: any) => {
      const d = res[this.type];
      if (item == "日") {
        if (d && d["测量时间"]) {
          this.time = this.getDate(d["测量时间"]);
        } else {
          this.time = this.getDate();
        }
      } else if (item == "周") {
        if (d && d["测量时间"]) {
          this.countWeekDay(this.getDate(d["测量时间"], "yyyy-MM-dd"));
        } else {
          this.countWeekDay(this.getDate("", "yyyy-MM-dd"));
        }
      } else if (item == "月") {
        if (d && d["测量时间"]) {
          this.time = this.getDate(d["测量时间"], "yyyy-MM");
        } else {
          this.time = this.getDate("", "yyyy-MM");
        }
      } else if (item == "年") {
        if (d && d["测量时间"]) {
          this.time = this.getDate(d["测量时间"], "yyyy");
        } else {
          this.time = this.getDate();
        }
      }
      // 更新折线图
      this.drawchart();
    });
    this.showEcharts = false;
  }
  private change() {
    // 更新折线图
    this.drawchart();
  }
  private changeTime(val: any) {
    if (val) {
      let timeStamp = val.getTime(); //标准时间转为时间戳，毫秒级别
      this.startTimeStamp = this.timeFun(timeStamp - 24 * 60 * 60 * 1000); //开始时间
      this.endTimeStamp = this.timeFun(timeStamp + 24 * 60 * 60 * 1000 * 5); //结束时间
    }
    // 更新折线图
    this.drawchart();
  }
  private getDate(time?: any, format?: any) {
    let date: any;
    if (time) {
      date = new Date(time);
    } else {
      date = new Date();
    }
    let year: any = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let day: any = date.getDate();
    month = month > 9 ? month : "0" + month;
    day = day > 9 ? day : "0" + day;
    if (format === "yyyy") {
      return `${year}`;
    } else if (format === "MM") {
      return `${month}`;
    } else if (format === "dd") {
      return `${day}`;
    } else if (format === "yyyy-MM") {
      return `${year}-${month}`;
    } else {
      return `${year}-${month}-${day}`;
    }
  }
  //时间戳转为yy-mm-dd hh:mm:ss
  private timeFun(val: any) {
    var unixtimestamp: any = new Date(val);
    var year = 1900 + unixtimestamp.getYear();
    var month = "0" + (unixtimestamp.getMonth() + 1);
    var date = "0" + unixtimestamp.getDate();
    const result =
      year +
      "-" +
      month.substring(month.length - 2, month.length) +
      "-" +
      date.substring(date.length - 2, date.length);
    return result;
  }
  private optChange() {
    // 更新折线图
    this.drawchart();
  }
  private openAdd() {
    // 如果是添加体重，需要判断有没有身高，如果没有需要强制填写
    if (this.type == "体重" && !this.patient["身高"]) {
      this.heightData = {};
      this.ifShowHeight = true;
      return;
    }
    const now: any = getCurrentTime("yyyy-MM-dd HH:mm");
    if (this.type == "运动") {
      // // 生成选项
      // this.hours = [];
      // this.minutes = [];
      // for (let i: any = 1; i < 24; i++) {
      //   this.hours.push(i + "小时");
      // }
      // for (let i: any = 1; i < 60; i++) {
      //   this.minutes.push(i + "分钟");
      // }
      this.addData = {
        测量时间: getCurrentTime("yyyy-MM-dd HH:mm:ss"),
      };
    } else {
      this.addData = {
        测量时间: now,
        类别: "",
      };
    }
    if (this.type == "血糖") {
      this.addData["类别"] = "空腹";
    } else if (this.type == "血压") {
      this.addData["类别"] = "静息";
    } else if (this.type == "心电") {
      this.addData["类别"] = "单导";
    } else if (this.type == "脉搏") {
      this.addData["类别"] = "静息";
    } else if (this.type == "心率") {
      this.addData["类别"] = "静息";
    }
    this.ifShowAdd = true;
  }
  private getNewst() {
    return new Promise((resolve, reject) => {
      const params: any = {
        // client_id: "clientuser62f32f3958c24d000a8b1171",
        patient_id: this.patient.patient_id,
        来源: "pc医生",
      };
      getNewQuotas(this, params).then((res: any) => {
        resolve(res);
      });
    });
  }
  private countWeekDay(e: any) {
    const oneDay = 86400000;
    // 计算开始时间和结束时间
    // 先获取所选时间是周几
    var day = new Date(e).getDay(); // 0是周日，1是周一
    // 如若是0，则结束时间是endTime
    if (day < 1) {
      this.startTimeStamp = e;
      const time = new Date(e).getTime() + oneDay * 6;
      const d = this.getDate(time);
      this.endTimeStamp = d;
    } else if (day === 6) {
      this.endTimeStamp = e;
      const time = new Date(e).getTime() - oneDay * 6;
      const d = this.getDate(time);
      this.startTimeStamp = d;
    } else {
      const cTime = new Date(e).getTime();
      const start = cTime - oneDay * day;
      const end = cTime + (6 - day) * oneDay;
      this.startTimeStamp = this.getDate(start);
      this.endTimeStamp = this.getDate(end);
    }
  }
  private drawchart() {
    if (!this.time) {
      return;
    }
    const params: any = {
      patient_id: this.patient.patient_id,
      type: this.type,
      period: "",
      period_type: this.currentPart,
      条件: this.cOpt,
      来源: "pc医生",
    };
    if (this.currentPart === "周") {
      params.period = this.startTimeStamp + ":" + this.endTimeStamp;
    } else {
      params.period = this.time;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostDeviceChart(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.showEcharts = false;
        this.chartData = data;
        const d: any = data.datas;
        const title: any = [];
        let d1: any = []; // 收缩压
        let d2: any = []; //舒张压
        let d3: any = []; //运动
        d.forEach((ele: any) => {
          title.push(ele.begin_time);
          if (this.type == "血压") {
            const obj1 = JSON.parse(JSON.stringify(ele));
            obj1.value = ele["舒张压"];
            d1.push(obj1);
            const obj2 = JSON.parse(JSON.stringify(ele));
            obj2.value = ele["收缩压"];
            d2.push(obj2);
          } else if (this.type == "运动") {
            const obj3 = JSON.parse(JSON.stringify(ele));
            obj3.value = ele["运动时长"];
            d3.push(obj3);
          }
        });
        if (this.type == "体重") {
          this.chartLine = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
              },
              formatter: (val: any) => {
                const text =
                  val[0].data.begin_time +
                  "\n" +
                  "体重: " +
                  val[0].data.value +
                  "\n" +
                  "BMI: " +
                  val[0].data.BMI;
                return `<span>${val[0].data.begin_time}</span><br/><span>体重：${val[0].data.value}</span><br/><span>BMI：${val[0].data.BMI}</span>`;
              },
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: title,
              axisLabel: {
                formatter: (val: any) => {
                  const text = val.split(" ")[0] + "\n" + val.split(" ")[1];
                  return text;
                },
              },
            },
            yAxis: {
              name: data.unit,
              type: "value",
              axisLabel: {
                formatter: "{value}",
              },
              axisPointer: {
                snap: true,
              },
            },
            series: [
              {
                name: this.type,
                type: "line",
                smooth: true,
                // prettier-ignore
                data: d,
                itemStyle: {
                  color: "#3387FF",
                },
                markArea: {
                  itemStyle: {
                    color: "rgba(51,135,255, 0.3)",
                  },
                  // data: [
                  //   [
                  //     {
                  //       yAxis: "3",
                  //     },
                  //     {
                  //       yAxis: "8",
                  //     },
                  //   ],
                  // ],
                },
              },
            ],
          };

          if (this.patient["身高"]) {
            // 反推体重范围
            const height = this.patient["身高"] / 100;
            let min = this.zhibiaoRange.BMI[0] * height * height;
            let max = this.zhibiaoRange.BMI[1] * height * height;
            this.chartLine.series[0].markArea.data = [
              [
                {
                  yAxis: min,
                },
                {
                  yAxis: max,
                },
              ],
            ];
          }
          setTimeout(() => {
            this.showEcharts = true;
          }, 100);
        } else if (this.type == "血压") {
          this.chartLine = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
              },
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: title,
              axisLabel: {
                formatter: (val: any) => {
                  const text = val.split(" ")[0] + "\n" + val.split(" ")[1];
                  return text;
                },
              },
            },
            yAxis: {
              name: data.unit,
              type: "value",
              axisLabel: {
                formatter: "{value}",
              },
              axisPointer: {
                snap: true,
              },
            },
            series: [
              {
                name: "舒张压",
                type: "line",
                smooth: true,
                data: d1,
                itemStyle: {
                  color: "#F5A021",
                },
                // markArea: {
                //   itemStyle: {
                //     color: "rgba(245,160,33, 0.3)",
                //   },
                //   data:
                //     this.cOpt && this.cOpt != "全部"
                //       ? [
                //           [
                //             {
                //               yAxis: this.zhibiaoRange[this.cOpt]["舒张压"][0],
                //             },
                //             {
                //               yAxis: this.zhibiaoRange[this.cOpt]["舒张压"][1],
                //             },
                //           ],
                //         ]
                //       : [],
                // },
              },
              {
                name: "收缩压",
                type: "line",
                smooth: true,
                data: d2,
                itemStyle: {
                  color: "#3387FF",
                },
                // markArea: {
                //   itemStyle: {
                //     color: "rgba(51,135,255, 0.3)",
                //   },
                //   data:
                //     this.cOpt && this.cOpt != "全部"
                //       ? [
                //           [
                //             {
                //               yAxis: this.zhibiaoRange[this.cOpt]["收缩压"][0],
                //             },
                //             {
                //               yAxis: this.zhibiaoRange[this.cOpt]["收缩压"][1],
                //             },
                //           ],
                //         ]
                //       : [],
                // },
              },
            ],
          };
          setTimeout(() => {
            this.showEcharts = true;
          }, 100);
        } else if (this.type == "运动") {
          this.chartLine = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
              },
              formatter: (val: any) => {
                return `<span>${val[0].data.begin_time}</span><br/><span>运动时长：${val[0].data.value}分钟</span><br/><span>运动类型：${val[0].data["运动类型"]}</span>`;
              },
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: title,
              axisLabel: {
                formatter: (val: any) => {
                  const text = val.split(" ")[0] + "\n" + val.split(" ")[1];
                  return text;
                },
              },
            },
            yAxis: {
              name: this.chartData.unit,
              type: "value",
              axisLabel: {
                formatter: "{value}",
              },
              axisPointer: {
                snap: true,
              },
            },
            series: [
              {
                name: this.type,
                type: "line",
                smooth: true,
                // prettier-ignore
                data: d3,
                itemStyle: {
                  color: "#3387FF",
                },
                markArea: {
                  itemStyle: {
                    color: "rgba(51,135,255, 0.3)",
                  },
                },
              },
            ],
          };
          setTimeout(() => {
            this.showEcharts = true;
          }, 100);
        } else {
          this.chartLine = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
              },
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: title,
              axisLabel: {
                formatter: (val: any) => {
                  const text = val.split(" ")[0] + "\n" + val.split(" ")[1];
                  return text;
                },
              },
            },
            yAxis: {
              name: data.unit,
              type: "value",
              axisLabel: {
                formatter: "{value}",
              },
              axisPointer: {
                snap: true,
              },
            },
            series: [
              {
                name: this.type,
                type: "line",
                smooth: true,
                // prettier-ignore
                data: d,
                itemStyle: {
                  color: "#3387FF",
                },
                markArea: {
                  itemStyle: {
                    color: "rgba(51,135,255, 0.3)",
                  },
                  // data: [
                  //   [
                  //     {
                  //       yAxis: "3",
                  //     },
                  //     {
                  //       yAxis: "8",
                  //     },
                  //   ],
                  // ],
                },
              },
            ],
          };
          // 除了全部，其他时间段需要用颜色标正常值范围
          if (this.cOpt && this.cOpt != "全部" && this.type == "心率") {
            this.chartLine.series[0].markArea.data = [
              [
                {
                  yAxis: this.zhibiaoRange[this.cOpt + "心率"][0],
                },
                {
                  yAxis: this.zhibiaoRange[this.cOpt + "心率"][1],
                },
              ],
            ];
          }
          if (this.cOpt && this.cOpt != "全部" && this.type == "血糖") {
            this.chartLine.series[0].markArea.data = [
              [
                {
                  yAxis: this.zhibiaoRange[this.cOpt][0],
                },
                {
                  yAxis: this.zhibiaoRange[this.cOpt][1],
                },
              ],
            ];
          }
          if (this.type == "血氧饱和度" || this.type == "体温") {
            this.chartLine.series[0].markArea.data = [
              [
                {
                  yAxis: this.zhibiaoRange[this.type][0],
                },
                {
                  yAxis: this.zhibiaoRange[this.type][1],
                },
              ],
            ];
          }
          setTimeout(() => {
            this.showEcharts = true;
          }, 100);
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  private mounted() {
    this.yundongOptions = [];
    getYundongType(this).then((res) => {
      const arr: any = [
        "健身类",
        "休闲运动",
        "球类",
        "水上项目",
        "冰雪项目",
        "极限运动",
      ];
      arr.forEach((ele: any) => {
        const obj = {
          name: ele,
          children: res[ele],
        };
        this.yundongOptions.push(obj);
      });
    });
  }
}
