






































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  getHistoryList,
  getYichangList,
  sendChuli,
  GetZhibiaoRange,
} from "@/request/yichang";
import XueyaRead from "../../workstation/components/zhinengjiance/components/xueya/read.vue";
import XuetangRead from "../../workstation/components/zhinengjiance/components/xuetang/read.vue";
import XinlvRead from "../../workstation/components/zhinengjiance/components/xinlv/read.vue";
import XueyangRead from "../../workstation/components/zhinengjiance/components/xueyang/read.vue";
import MaiboRead from "../../workstation/components/zhinengjiance/components/maibo/read.vue";
import TiwenRead from "../../workstation/components/zhinengjiance/components/tiwen/read.vue";
import TizhongRead from "../../workstation/components/zhinengjiance/components/tizhong/read.vue";
import YundongRead from "../../workstation/components/zhinengjiance/components/yundong/read.vue";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    XueyaRead,
    XuetangRead,
    XinlvRead,
    XueyangRead,
    MaiboRead,
    TiwenRead,
    TizhongRead,
    YundongRead,
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private visible: any;
  @Prop()
  private part: any;
  @Prop()
  private parentData: any;
  @Watch("visible", { immediate: true })
  private visibleChange() {
    if (this.visible) {
      this.resetData();
    }
  }
  @Watch("part", { immediate: true })
  private partChange() {
    if (this.part) {
      if (this.part == "血压") {
        this.cTab = {
          name: "血压",
          label: "血压",
        };
      } else if (this.part == "血糖") {
        this.cTab = {
          name: "血糖",
          label: "血糖",
        };
      } else if (this.part == "心率") {
        this.cTab = {
          name: "心率",
          label: "心率",
        };
      } else if (this.part == "血氧") {
        this.cTab = {
          name: "血氧饱和度",
          label: "血氧",
        };
      } else if (this.part == "脉搏") {
        this.cTab = {
          name: "脉搏",
          label: "脉搏",
        };
      } else if (this.part == "体温") {
        this.cTab = {
          name: "体温",
          label: "体温",
        };
      } else if (this.part == "体重") {
        this.cTab = {
          name: "体重",
          label: "体重",
        };
      }
    }
  }
  private data: any = [];
  private list: any = [];
  private yichangList: any = [];
  private topTab: any = [
    {
      name: "血糖",
      label: "血糖",
    },
    {
      name: "血压",
      label: "血压",
    },
    {
      name: "心率",
      label: "心率",
    },
    {
      name: "运动",
      label: "运动",
    },
    {
      name: "体重",
      label: "体重",
    },
    {
      name: "血氧饱和度",
      label: "血氧",
    },
    {
      name: "体温",
      label: "体温",
    },
  ];
  private ranges: any = {};
  private cTab: any = { name: "血压", label: "血压" };
  private ifShow: any = false;
  private readTime: any = new Date().getTime();
  private get isShow() {
    return this.visible;
  }
  private set isShow(val: any) {
    this.cancel();
  }
  private get user() {
    return this.$store.state.user;
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private changeTab(item: any) {
    this.cTab = item;
    this.readTime = new Date().getTime();
  }
  private resetData() {
    this.data = [];
    this.getList();
    this.getData();
    this.getRanges();
  }
  private change() {
    this.data.content1 = "";
    this.data.content2 = "";
    this.data.content3 = "";
  }
  private getRanges() {
    const params: any = {
      params: {
        patient_id: this.patient.patient_id,
      },
    };
    GetZhibiaoRange(this, params).then((res: any) => {
      this.ranges = res;
    });
  }
  private add() {
    this.data.push({
      类型: "",
      处理内容: "",
    });
  }
  private remove(i: any) {
    this.data.splice(i, 1);
  }
  private getList() {}
  private getData() {
    const params = {
      patient_id: this.patient.patient_id,
    };
    getHistoryList(this, params).then((res: any) => {
      this.list = res;
    });
    const params2 = {
      patient_id: this.patient.patient_id,
      source: "pc医生",
    };
    getYichangList(this, params2).then((res: any) => {
      this.yichangList = res;
    });
  }
  private cancel() {
    this.$emit("close");
  }
  private send() {
    if (this.data.length == 0) {
      this.$message.error("请添加至少一条异常值处理后才能发送消息给当前患者。");
      return;
    } else {
      for (const iterator of this.data) {
        if (!iterator["处理内容"] || !iterator["类型"]) {
          this.$message.error("还有必填项未填！");
          return;
        }
      }
    }
    const params = {
      patient_id: this.patient.patient_id,
      check_time: this.parentData.check_time,
      handles: this.data,
    };
    sendChuli(this, params).then((res: any) => {
      this.$message.success("处理成功！");
      this.resetData();
      this.$emit("close");
    });
  }
}
