





































import { Component, Vue, Prop } from "vue-property-decorator";
import { getControlData } from "../common";
import Title from "./title.vue";
import SubTitle from "./title-sub.vue";
@Component({
  components: {
    Title,
    SubTitle,
  },
})
export default class Name extends Vue {
  @Prop()
  private content: any;
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  private get c() {
    return JSON.parse(JSON.stringify(this.content));
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private radioClick(ev: any) {
    if (!this.ifCompose && this.source !== "dialog") {
      return;
    }
    this.c.value === ev ? (this.c.value = "") : (this.c.value = ev);
    if (this.source === "dialog") {
      this.$emit("updateDialog", this.c);
    } else {
      this.$emit("update", this.c);
      this.changeHide();
    }
  }
  /**
   * @description 题目联动
   */
  private changeHide() {
    // 有control字段才需要联动
    if (!this.c.control) {
      return;
    }
    const data = getControlData(this.c);
    this.$emit("changeHide", data);
  }
}
