var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"editor-wrap"},[(_vm.data.filelist && _vm.data.filelist.length > 0)?[_c('div',{staticClass:"fileBox"},[(
            _vm.data.filelist[0].type == 'png' ||
            _vm.data.filelist[0].type == 'jpg' ||
            _vm.data.filelist[0].type == 'jpeg'
          )?_c('img',{attrs:{"src":_vm.data.filelist[0].url,"alt":""},on:{"click":function($event){return _vm.read(_vm.data.filelist[0].url)}}}):_vm._e(),(_vm.data.filelist[0].type == 'pdf')?_c('img',{attrs:{"src":"/common/pdf.png","alt":""},on:{"click":function($event){return _vm.read(_vm.data.filelist[0].url)}}}):_vm._e()])]:[(_vm.data && _vm.data['方案'] && _vm.data['方案'].content)?_c('DsEditor',{attrs:{"readOnly":"","ifHideScroll":"","autoHeight":"","autoBottom":"24","data":_vm.data['方案'].content}}):_vm._e()],(
        (!_vm.data.filelist || _vm.data.filelist.length == 0) &&
        (!_vm.data['方案'] || !_vm.data['方案'].content)
      )?[_c('Empty')]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }