
































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import TimeRange from "@/components/time-range/time-range.vue";
import { getQushiChart } from "@/request/custom";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    TimeRange,
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private origin: any;
  @Prop()
  private status: any;
  @Prop()
  private ifShowQushi: any;
  @Watch("ifShowQushi", { immediate: true })
  private ifShowQushiChange() {
    this.ifShow = this.ifShowQushi;
    this.qushiFilter = {};
    this.getData();
  }
  @Prop()
  private targetData: any;
  private ifShow: any = false;
  private qushiFilter: any = {};
  private chartLine: any = {};
  private qushiData: any = {};
  private data: any = {};
  private get patient() {
    return this.$store.state.patient;
  }
  private qushiTimeChange(item: any) {
    this.qushiFilter = item;
    this.getData();
  }
  private getData() {
    if (!this.targetData.index_name) {
      return;
    }
    const params: any = {
      params: {
        patient_id: this.patient.patient_id,
        index_name: this.targetData.index_name,
        start_time: this.qushiFilter.startTime,
        end_time: this.qushiFilter.endTime,
        origin: this.origin,
        status: this.status,
      },
    };
    getQushiChart(this, params).then((res) => {
      res.data.forEach((ele: any) => {
        ele.value = ele["结果"];
        ele.name = ele.inspect_time;
      });
      this.data = res;
      this.chartLine = {
        grid: {
          top: 10,
          bottom: 40,
          left: 80,
          right: 80,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: res.name,
          // axisLabel: {
          //   formatter: (val: any) => {
          //     const text = val.split(" ")[0] + "\n" + val.split(" ")[1];
          //     return text;
          //   },
          // },
        },
        yAxis: {
          type: "value",
          axisPointer: {
            snap: true,
          },
        },
        series: [
          {
            name: this.targetData.name,
            type: "line",
            smooth: true,
            // prettier-ignore
            data: res.data,
            itemStyle: {
              color: "#3387FF",
            },
          },
        ],
      };
    });
  }
  private close() {
    this.$emit("close");
  }
  private mounted() {
    this.getData();
  }
}
