









































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { getTimeline } from "@/request/custom";
import Zhenliaojilu from "../../components/zhenliaojilu/zhenliaojilu.vue";
import Jiankangshebei from "../../components/jiankangshebei/jiankangshebei.vue";
import Jiankangceping from "../../components/jiankangceping/jiankangceping.vue";
import Zhinengjiance from "../../components/zhinengjiance/zhinengjiance.vue";
import Jianyan from "../../components/jianyan/jianyan.vue";
import Jiancha from "../../components/jiancha/jiancha.vue";
import Pinggubaogao from "../../components/pinggubaogao/pinggubaogao.vue";
import Ganyu from "../../components/ganyu/ganyu.vue";
import Yizhu from "../../components/yizhu/yizhu.vue";
import Suifangshuju from "../../components/suifangshuju/suifangshuju.vue";
@Component({
  components: {
    Zhenliaojilu,
    Jiankangshebei,
    Jiankangceping,
    Zhinengjiance,
    Jianyan,
    Jiancha,
    Pinggubaogao,
    Ganyu,
    Yizhu,
    Suifangshuju,
  },
})
export default class Name extends Vue {
  @Prop()
  private leftTabChangeTime: any;
  @Watch("leftTabChangeTime", { immediate: true })
  private leftTabChangeTimeChange() {
    this.countTimeW();
  }
  private times: any = [];
  private ifOverstep: any = false; // 时间轴是否溢出，用来控制上一个和下一个按钮是否显示
  private oneTimeW: any = 120; // 单个时间轴的宽度
  private wrapW: any = 0;
  private contentH: any = 0; // 主内容的高度
  private get configuration() {
    return this.$store.state.configuration;
  }
  private get patientFileFilter() {
    return this.$store.state.patientFileFilter;
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private get resizeTime() {
    return this.$store.state.resizeTime;
  }
  @Watch("resizeTime", { immediate: true })
  private resizeTimeChange() {
    if (this.resizeTime) {
      this.getTimeData();
    }
  }
  private refresh() {
    this.changeTab(this.configuration.danganNavs[0]);
  }
  private goPinggu(item: any) {
    if (item.type == "评估") {
      this.$router.push({
        path: "/main/workstation/patient-pinggu-report",
        query: {
          id: item.assess_id,
        },
      });
    } else if (item.type == "干预") {
      this.$router.push({
        path: "/main/workstation/patient-ganyu-edit",
        query: {
          id: item.assess_id,
        },
      });
    }
  }
  private getTimeData() {
    const params: any = {
      params: {
        patient_id: this.patient.patient_id,
      },
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    getTimeline(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.times = res;
        this.countTimeW();
      })
      .catch(() => {
        loading.close();
      });
  }
  private changeTab(item: any) {
    // if (this.patientFileFilter.secondTab == item.value) {
    //   return;
    // }
    const d = JSON.parse(JSON.stringify(this.patientFileFilter));
    d.secondTab = item.value;
    d.thirdTab = "";
    d.fourTab = "";
    d.fourOpenId = "";
    this.$store.commit("updatePatientFileFilter", d);
    this.$forceUpdate();
  }
  private countTimeW() {
    this.$nextTick(() => {
      const W: any = (this.$refs.times as any).offsetWidth;
      if (this.times.length * this.oneTimeW > W) {
        this.ifOverstep = true;
        // 计算数量,100为预留的上一个，下一个图标位置
        const num: any = Math.floor((W - 100) / this.oneTimeW);
        this.wrapW = this.oneTimeW * num;
      } else {
        this.wrapW = this.oneTimeW * this.times.length;
        this.ifOverstep = false;
      }
      // 每次算完时间轴回到初始状态
      const ele: any = document.getElementById("shijianzhou");
      ele.style.left = 0;

      // 计算主内容高度
      const H: any =
        document.documentElement.clientHeight || document.body.clientHeight;
      const Top = (this.$refs.tabContent as any).getBoundingClientRect().top;
      this.contentH = H - Top - 14;
    });
  }
  private goBefore() {
    const ele: any = document.getElementById("shijianzhou");
    const cLeft = ele.style.left ? Number(ele.style.left.split("px")[0]) : 0;
    if (cLeft > -this.oneTimeW) {
      this.$message.warning("已经到底了");
      return;
    } else {
      ele.style.left = cLeft + this.oneTimeW + "px";
    }
  }
  private goNext() {
    const maxL = this.oneTimeW * this.times.length - this.wrapW;
    const ele: any = document.getElementById("shijianzhou");
    const cLeft = ele.style.left ? Number(ele.style.left.split("px")[0]) : 0;
    if (-cLeft + this.oneTimeW > maxL) {
      this.$message.warning("已经到底了");
      return;
    } else {
      ele.style.left = cLeft - this.oneTimeW + "px";
    }
  }
  private mounted() {
    this.getTimeData();
    // 如果档案记录且没有选中项，默认选中第一个
    if (
      !this.patientFileFilter.secondTab &&
      this.patientFileFilter.firstTab == "档案记录"
    ) {
      this.changeTab(this.configuration.danganNavs[0]);
    }
  }
}
