import { render, staticRenderFns } from "./record.vue?vue&type=template&id=f77486c8&scoped=true&"
import script from "./record.vue?vue&type=script&lang=ts&"
export * from "./record.vue?vue&type=script&lang=ts&"
import style0 from "./record.vue?vue&type=style&index=0&id=f77486c8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f77486c8",
  null
  
)

export default component.exports