


























































import { Component, Vue, Prop } from "vue-property-decorator";
import Title from "./title.vue";
import SubTitle from "./title-sub.vue";
const TemplateTransfer = () =>
  import("../template-transfer.vue");

@Component({
  components: {
    Title,
    SubTitle,
    TemplateTransfer,
  },
})
export default class Name extends Vue {
  @Prop()
  private content: any;
  @Prop()
  private index: any;
  @Prop()
  private source: any; //dialog,审核修改弹框;renkouxue,人口学和受试者信息;
  private get c() {
    const d = JSON.parse(JSON.stringify(this.content));
    if (!d.value || d.value.length === 0) {
      d.value.push(JSON.parse(JSON.stringify(d.basic)));
    }
    this.$forceUpdate();
    return d;
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private valueChange() {
    if (this.source === "dialog") {
      this.$emit("updateDialog", this.c);
    } else {
      this.$emit("update", this.c);
    }
  }
  private updateContent(e: any, i: any) {
    this.c.value[i][e.index] = e.data;
    this.valueChange();
  }
  private delOne(index: any) {
    this.c.value.splice(index, 1);
    this.valueChange();
  }
  private addOne() {
    this.c.value.push(JSON.parse(JSON.stringify(this.c.basic)));
    this.valueChange();
  }
}
