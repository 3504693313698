





















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends Vue {
  @Prop({ type: Array, default: () => [] })
  private leftNavList: any;
  @Prop({ type: String, default: "就诊时间" })
  private timeKey: any;
  @Prop({ type: String, default: "名称" })
  private label: any;
  @Prop({ type: String, default: "id" })
  private keyValue: any;
  @Prop({ type: Boolean, default: false })
  private hideTool: any;
  private zhuyuanType: any = ["病案首页", "入院记录", "首次病程录", "出院记录"];
  private get patientFileFilter() {
    return this.$store.state.patientFileFilter;
  }
  private get patientFileFilterRefrshTime() {
    return this.$store.state.patientFileFilterRefrshTime;
  }
  @Watch("patientFileFilterRefrshTime", { immediate: true })
  private resizeTimeChange() {
    if (
      this.patientFileFilterRefrshTime &&
      this.leftNavList &&
      this.leftNavList.length > 0
    ) {
      this.changeLeft(this.leftNavList[0]);
    }
  }
  private changeLeft(item: any, parent?: any) {
    const d = JSON.parse(JSON.stringify(this.patientFileFilter));
    if (!item) {
      return;
    }
    d.thirdTab = item;
    d.fourTab = "";
    d.fourOpenId = "";
    this.$store.commit("updatePatientFileFilter", d);
    this.$emit("updateData");
  }
  private handleCommand(e: any) {
    // this.editData = e.value;
    if (e.type == "删除") {
      this.$confirm("确定删除该测评么？", "提交", {
        customClass: "commonConfirm",
      })
        .then(() => {
          this.$emit("delOne", e.value);
        })
        .catch((e) => {
          console.log("取消了");
        });
    }
  }
}
