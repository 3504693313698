












































import { Component, Vue, Prop } from "vue-property-decorator";
import DsEditor from "@/components/ds-editor/ds-editor.vue";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    DsEditor,
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private data: any;
  private read(url: any) {
    window.open(url);
  }
}
