
































import { Component, Vue, Prop } from "vue-property-decorator";
import TemplateTransfer from "@/components/template-transfer-pc/template-transfer/template-transfer.vue";
@Component({
  components: {
    TemplateTransfer,
  },
})
export default class Name extends Vue {
  @Prop()
  private data: any;

  private get c() {
    return JSON.parse(JSON.stringify(this.data));
  }
}
