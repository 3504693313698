



















































































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "../../../main/components/main-header/main-header.vue";
import NavLeft from "../../../main/components/nav-left/nav-left.vue";
import LeftPatient from "../../components/left-patient/left-patient.vue";
import Huaxiang from "../../components/huaxiang/huaxiang.vue";
import Record from "../../components/record/record.vue";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    LeftPatient,
    Huaxiang,
    Record,
    Empty,
  },
})
export default class Name extends Vue {
  private leftTabChangeTime: any = ""; // 左边收起展开时需要重新渲染echarts
  private IfShowAddFangan: any = false;
  private addValue: any = "";
  private get patient() {
    return this.$store.state.patient;
  }
  private get user() {
    return this.$store.state.user || {};
  }
  private get patientFileFilter() {
    return this.$store.state.patientFileFilter;
  }
  private openDingzhifangan() {
    this.addValue = "自定义方案";
    this.IfShowAddFangan = true;
  }
  private addSure() {
    if (!this.addValue) {
      this.$message.warning("请选择方案类型！");
      return;
    }
    this.IfShowAddFangan = false;
    this.$router.push({
      path: "/main/workstation/patient-ganyu-edit",
      query: {
        type: this.addValue,
      },
    });
    this.$store.commit("updateIfCompose", true);
  }
  private changePatient() {
    this.changeFileTab("健康画像");
    if (this.$refs.myHuaxiang) {
      (this.$refs.myHuaxiang as any).getData();
    }
    if (this.$refs.myRecord) {
      (this.$refs.myRecord as any).refresh();
    }
  }
  private changeFileTab(val: any) {
    const d = JSON.parse(JSON.stringify(this.patientFileFilter));
    d.firstTab = val;
    this.$store.commit("updatePatientFileFilter", d);
  }
  private changeLeft(val: any) {
    const d = JSON.parse(JSON.stringify(this.patientFileFilter));
    d.isHideLeft = val;
    this.$store.commit("updatePatientFileFilter", d);
    this.leftTabChangeTime = new Date().getTime();
  }
}
